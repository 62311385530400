.profile-dashboard {
    width: 100%;

    .title {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 0 0 0;
        top: 70px;
        width: calc(100% - 50px);
        background-color: var(--background-color);
        z-index: 98;
        max-width: 1250px;

        h1 {
            margin: 0;
        }

        #desktop-sign-out {
            width: fit-content;
        }
    }

    @media only screen and (max-width: 740px) {
        .title {
            width: calc(100% - 30px);
        }
    }

    .profile-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 150px;
        margin-bottom: 25px;

        h2 {
            font-size: 36pt;
            font-weight: 200;
        }

        h3 {
            font-weight: 600;
            margin-bottom: 7px;
            display: flex;
            align-items: center;

            .pro-badge {
                background-color: var(--shoebox-yellow);
                color: var(--consistent-main);
                padding: 4px 8px;
                margin-left: 12px;
                border-radius: 10px;
                font-size: 12pt;
                font-weight: 900;
            }
        }

        img {
            border-radius: 100px;
            height: 100px;
            width: 100px;
        }

        .profile-picture-loader {
            border-radius: 100px;
            height: 100px;
            width: 100px;
            --sheen-color: var(--background-color);
            --color: var(--tile-background);
        }

        .profile-image-placeholder {
            border-radius: 100px;
            height: 100px;
            width: 100px;

            .icon-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100px;
                width: 100px;
            }

            svg {
                color: var(--background-color);
                font-size: 42pt;
            }
        }
    }

    .profile-body {
        display: grid;
        width: calc(100% - 45px);
        grid-template-columns: 50% 50%;
        column-gap: 45px;
        max-width: 1000px;

        section {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;
    
            .button-row {
                display: grid;
                grid-template-columns: 50% 50%;
                column-gap: 12px;
                justify-content: space-between;
                width: calc(100% - 12px);
            }
    
            .tri-button-row {
                display: grid;
                grid-template-columns: 33.3% 33.3% 33.3%;
                column-gap: 12px;
                width: calc(100% - 24px);
            }

            .single-item {
                margin-top: 12px;
            }
        }
    
        .preferences {
            margin-bottom: 5px;

            sl-radio-group.account-preferences-button-groups::part(button-group) {
                width: 100%;
                max-width: 500px;
            }

            sl-radio-button.preference-button {
                width: 100%;
            }

            sl-radio-button.preference-button::part(button) {
                width: 100%;
                min-width: 75px;
            }

            .weighting-method-control-label {
                display: flex;
                align-items: center;

                svg {
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }

        #weighting-help-popup {

            h4 {
                display: flex;
                align-items: center;
                font-size: 14pt;
                font-weight: 600;
                margin-bottom: 6px;
                margin-top: 10px;
        
                svg {
                    font-size: 17pt;
                    margin-right: 3px;
                    margin-bottom: 1px;
                    color: var(--tile-info-icon);
        
                    &.dollar-icon {
                        margin-top: 2px;
                        margin-right: 5px;
                    }
                }
            }
        
            h4:first-of-type {
                margin-top: 0;
            }
        
            p {
                color: var(--text-color-secondary);
                padding-bottom: 10px;
                display: block;

                span {
                 color: var(--text-color-secondary);
                 font-weight: 600;
                 font-style: oblique;
                }
            }
        
            p:last-of-type {
                margin-bottom: 0px;
            }
        }
    
        .support {
            margin-bottom: 0;
        }
        
        sl-button {
            width: 100%;
        }
    
        sl-radio-group.account-preferences-button-groups::part(form-control) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 20px;
        }
    
        sl-radio-group.account-preferences-button-groups::part(form-control-label) {
            margin-bottom: 10px;
        }
    }

    @media only screen and (max-width: 800px) {
        .profile-body {
            display: flex;
            flex-direction: column;
            width: unset;
            max-width: unset;
            margin: auto;
        }
    }
}

.settings-sub-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    .title {
        display: flex;
        align-items: center;

        h1 {
            margin: 0;
        }

        sl-button.sub-setting-back-button::part(base) {
            width: 42px;
            height: 42px;
            border-radius: 50px;
            margin-right: 12px;
        }
    
        sl-button.sub-setting-back-button::part(label) {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24pt;
        }
    }
}

@media only screen and (max-width: 450px) {
    .settings-sub-header {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        h1 {
            font-size: 24pt;
        }
    
        sl-button.sub-setting-back-button::part(base) {
            width: 32px;
            height: 32px;
            border-radius: 50px;
            margin-right: 12px;
        }
    
        sl-button.sub-setting-back-button::part(label) {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18pt;
        }
    }
}

@media only screen and (max-width: 500px) {

    .profile-dashboard {

        .title {
            width: calc(100% - 30px);
        }

        .profile-header {
            margin-top: 135px;
        }

        .support {
            margin-bottom: 30px;
        }

        .sign-out-mobile {
            margin-top: 30px;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 425px) {

    .profile-dashboard {

        .profile-header {

            margin-top: 125px;
            
            img {
                height: 75px;
                width: 75px;
                margin-top: 10px;
            }

            h3 {
                margin: 0;
            }
        }
    }
}