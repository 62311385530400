#contribution-tile {
    display: flex;
    flex-direction: column;
 
    .tile-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        height: 36px;

        .see-all-btn {
            color: var(--shoebox-yellow);
            font-weight: 500;
            cursor: pointer;
        }
    }

    .empty-contributions {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: var(--text-color-secondary);
        height: calc(100% - 60px);
        text-align: center;
        margin-top: 20px;

        svg {
            font-size: 24pt;
            color: var(--shoebox-yellow);
            margin-bottom: 10px;
        }

        .line-with-link {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            color: var(--text-color-secondary);

            a {
                color: var(--text-color-secondary);
                text-decoration: underline;
                font-weight: 500;
                margin: 0 5px;
            }
        }
    }

    .automated-status-line {
        display: flex;
        align-items: center;
        justify-content: center;

        .status-text {
            display: flex;
            align-items: center;

            .indicator {
                margin-bottom: 2px;
            }

            span {
                color: var(--text-color-secondary);
                font-size: 10pt;
                margin-bottom: 1px;
            }
        }

        .schedule-link {
            margin-left: 10px;
            color: var(--shoebox-yellow);
            font-weight: 500;
            cursor: pointer;
        }
    }
    
    .add-one-time-btn {
        width: fit-content;
        justify-self: flex-end;
        align-self: flex-end;

        sl-button {
            width: 100%;
        }
    }

    .contribution-tile-footer {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: 7px;
        padding-top: 10px;
    }

    @media only screen and (max-width: 425px) {
        .contribution-tile-footer {
            flex-direction: column;
            margin-bottom: 2px;
        }

        .add-one-time-btn {
            width: 100%;
        }

        .automated-status-line {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .contribution-tile-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: calc(100% - 60px);
    }

    sl-drawer#all-contributions-drawer::part(header) {
        height: 95px;
    }

    #all-contributions-drawer {
        .drawer-header {
            align-items: flex-start;

            .close-btn {
                margin-top: 3px;
            }

            .left-side {
                background-color: var(--background-color);
                    padding-bottom: 5px;
                .table-legend {
                    display: flex;
                    align-items: center;
                    margin-bottom: 5px;
                    

                    small {
                        display: flex;
                        align-items: center;
                        margin-right: 12px;
                        margin-bottom: 0;
                        color: var(--text-color-tertiary);
                        font-size: 10pt;

                        svg {
                            margin-right: 4px;
                            font-size: 14pt;
                            color: var(--text-color-secondary);
                            transform: rotate(0);
                        }

                        &.one-time {
                            svg {
                                margin-right: 1px;
                            }
                        }
                    }
                }
            }
        }
        .table-wrapper {
            padding: 20px;
            padding-top: 0;
        }

        
    }
}