.configuration-dashboard {
    margin-top: 160px;
    overflow: hidden;
    height: fit-content;
}

.configuration-header {
    position: fixed;
    padding-top: 10px;
    top: 70px;
    width: calc(100% - 50px);
    max-width: 1250px;
    background-color: var(--background-color);
    z-index: 98;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        display: flex;
        align-items: center;
        margin-bottom: 15px;

        h1 {
            margin: 0;
        }

        sl-button.portfolio-back-button::part(base) {
            width: 42px;
            height: 42px;
            border-radius: 50px;
            margin-right: 12px;
        }
    
        sl-button.portfolio-back-button::part(label) {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24pt;
        }
    }

    .config-help {
        font-size: 24pt;
        color: var(--text-color-main);
        cursor: pointer;
        margin-bottom: 15px;
    }
}

.intro-banner {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    opacity: 0;
    will-change: filter;
    filter: blur(10px);
    padding: 0;
    background-color: var(--tile-background);
    transition: all 0.4s ease;

    &.open {
        grid-template-rows: 1fr;
        padding: 20px;
        will-change: filter;
        filter: blur(0px);
        border-radius: 15px;
        opacity: 1;
        margin-bottom: 35px;
    }
}

.banner-container {
    min-height: 0;
    display: flex;
    flex-direction: column;
    position: relative;
    font-size: 12pt;
        
    .close-start-banner {
        font-size: 20pt;
        color: var(--text-color-tertiary);
        cursor: pointer;
        position: fixed;
        top: 0;
        right: 0;
        padding: 10px;
    }

    h4 {
        font-weight: 600;
        font-size: 14pt;
        margin-bottom: 10px;
        display: flex;
        align-items: center;

        svg {
            font-size: 20pt;
            margin-right: 5px;
        }
    }

    .banner-text {
        display: flex;
        align-items: center;

        a {
            color: var(--shoebox-yellow);
            font-weight: 600;
            cursor: pointer;
        }

        .config-help {
            color: var(--text-color-main);
            font-size: 18pt;
            cursor: pointer;
        }

        .config-help, a {
            padding: 0 5px;
        }
    }
}

.account-overview {
    position: relative;
    span {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .edit-account-value {
            color: var(--shoebox-yellow);
            font-weight: 500;
            margin-right: 10px;
            transition: color 0.3s ease;
            cursor: pointer;

            body.hasHover &:hover {
                color: var(--shoebox-hover);
            }

            &.active {
                display: flex;
                align-items: center;
                cursor: default;

                .cancel-edit {
                    color: var(--text-color-secondary);
                    font-weight: 400;
                    cursor: pointer;

                    &.disabled {
                        cursor: not-allowed;
                        opacity: 0.6;
                    }
                }

                .save-edit {
                    color: var(--shoebox-yellow);
                    cursor: pointer;

                    &.disabled {
                        cursor: not-allowed;
                        opacity: 0.6;
                    }
                }

                .actions-divider {
                    padding: 0 10px;
                    color: var(--text-color-tertiary);
                }
            }
        }
    }
    .account-value-window {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24pt;
        font-family: "Reddit Mono", monospace;
        font-weight: 500;
        background-color: var(--tile-background);
        margin: 0 2px 30px 2px;
        border-radius: 15px;
        height: 63px;
        overflow: hidden;

        &.editable {
            text-align: center;
            width: calc(100% - 34px);
            padding: 0 15px;
        }

        .set-account-value {
            font-family: "Reddit Mono", monospace;

            &.animated {
                animation: fade-in-acct-value 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

                @keyframes fade-in-acct-value {
                    0% {
                        transform: translateY(-40px);
                        opacity: 0;
                    }
                    100% {
                        transform: translateY(0);
                        opacity: 1;
                    }
                }
            }
            

            .not-set-indicator {
                font-family: 'Inter', sans-serif;
                font-size: 20pt;
                font-weight: 500;
                color: var(--text-color-tertiary);
            }
        }
    }
}

.contributions-configuration, .investments-configuration {

    .contributions-configuration-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            margin-bottom: 10px;
            margin-right: 3px;
            color: var(--shoebox-yellow);
            font-weight: 500;
            cursor: pointer;
            display: flex;
            align-items: center;
            
            &.expanded {
                animation: blur-in-expand 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                @keyframes blur-in-expand {
                    0% {
                        transform: scale(0.8);
                        opacity: 0;
                        will-change: filter;
                        filter: blur(5px);
                    }
                    100% {
                        transform: scale(1);
                        opacity: 1;
                        will-change: filter;
                        filter: blur(0px);
                    }
                }
            }

            &.collapsed {
                animation: blur-in-collapse 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

                @keyframes blur-in-collapse {
                    0% {
                        transform: scale(0.8);
                        opacity: 0;
                        will-change: filter;
                        filter: blur(5px);
                    }
                    100% {
                        transform: scale(1);
                        opacity: 1;
                        will-change: filter;
                        filter: blur(0px);
                    }
                }
            }

            svg {
                font-size: 14pt;
                margin-left: 5px;
            }
        }
    }
    
    small {
        margin-bottom: 10px;
        display: block;
    }
}

.investments-configuration {
    margin-bottom: 0;

    .investment-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            font-weight: 500;
            color: var(--shoebox-yellow);
            cursor: pointer;

            svg {
                margin-right: 3px;
            }
        }
    }
}

sl-dialog#index-disclosure-popup::part(panel) {
    --width: 43rem;
}

#index-disclosure-popup {
    h4 {
        color: var(--shoebox-error);
    }
}

#config-help-dialog, #index-disclosure-popup {

    h4 {
        display: flex;
        align-items: center;
        font-size: 14pt;
        font-weight: 600;
        margin-bottom: 6px;
        margin-top: 10px;

        svg {
            font-size: 15pt;
            margin-right: 5px;
            margin-bottom: 1px;
            color: var(--tile-info-icon);

            &.config-icon {
                font-size: 17pt;
            }

            &.index-popup-icon {
                font-size: 18pt;
            }
        }
    }

    h4:first-of-type {
        margin-top: 0;
    }

    p {
        color: var(--text-color-secondary);
        padding-bottom: 10px;
        display: block;

        .link {
            color: var(--shoebox-yellow);
            font-weight: 600;
        }

        .bold {
            color: var(--text-color-secondary);
            font-weight: 600;
        }

        .italic {
            font-style: italic;
            color: var(--text-color-secondary);
        }
    }

    p:last-of-type {
        margin-bottom: 0px;
    }

    table {
        tr {
            vertical-align: baseline;

            th {
                text-align: left;
                color: var(--text-color-tertiary);
                font-weight: 500;
            }

            th:not(:last-of-type), td:not(:last-of-type) {
                padding-right: 25px;
            }

            .symbol {
                font-family: 'Reddit Mono', monospace;
                letter-spacing: 2px;
                font-weight: 500;
            }
        }
    }

    @media only screen and (max-width: 500px) {
        table {
            tr {
                td, th {
                    padding-bottom: 10px;
                }
            }
        }
    }
}

@media only screen and (max-width: 740px) {
    .portfolio-header {
        width: calc(100% - 35px);
        padding: 10px 10px 0 10px;

        a {
            padding-right: 5px;
        }
    }

    .configuration-header {
        width: calc(100% - 30px);
    }

    .contributions-configuration {
        margin-bottom: 30px;
    }
}

.config-sections {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 50px;
    width: calc(100% - 50px);
}

@media only screen and (max-width: 1050px) {
    .config-sections {
        column-gap: 25px;
        width: calc(100% - 25px);
    }
}

@media only screen and (max-width: 850px) {
    .config-sections {
        grid-template-columns: 1fr;
        column-gap: unset;
        width: 100%;
        margin-bottom: 100px;
    }
}

@media only screen and (max-width: 450px) {
    .configuration-header {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        h1 {
            font-size: 24pt;
        }
    
        sl-button.portfolio-back-button::part(base) {
            width: 32px;
            height: 32px;
            border-radius: 50px;
            margin-right: 12px;
        }
    
        sl-button.portfolio-back-button::part(label) {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18pt;
        }
    }
}