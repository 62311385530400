.expense-header {
    position: fixed;
    padding-top: 10px;
    top: 70px;
    width: calc(100% - 50px);
    max-width: 1250px;
    background-color: var(--background-color);
    z-index: 98;
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
        font-size: 28pt;
        padding: 0 3px;
        margin-bottom: 17px;
        color: var(--shoebox-yellow);
        cursor: pointer;
        transition: all 0.4s ease;

        &.open-view {
            transform: rotate(45deg);
            color: var(--button-secondary);
        }
    }

    .add-expense-btn {
        margin-bottom: 17px;

        svg {
            font-size: 18pt;
            padding: 0;
            margin: 0;
            margin-right: 5px;
            color: var(--consistent-main);
        }
    }

    .add-expense-btn::part(label) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h1 {
        margin-bottom: 15px;
    }
}

.expense-detail {
    width: calc(100% - 30px);
    max-width: 520px;
    margin-top: 150px;
    background-color: var(--tile-background);
    border-radius: 25px;
    padding: 25px;
    height: fit-content;
    animation: fade-in-expense-detail 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    @keyframes fade-in-expense-detail {
        0% {
            -webkit-transform: translateY(10px);
            transform: translateY(10px);
            opacity: 0;
            will-change: filter;
            filter: blur(5px);
        }
        100% {
            -webkit-transform: translateY(0);
            transform: translateY(0);
            opacity: 1;
            will-change: filter;
            filter: blur(0);
        }
    }

    .detail-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        h3 {
            font-size: 24pt;
        }

        .date-line {
            display: flex;
            align-items: center;
        }

        .withdrawn-divider {
            padding: 0 7px;
            color: var(--button-secondary);
        }

        .withdrawn-text {
            font-weight: 600;
            color: var(--shoebox-error);
        }

        .detail-card-actions {
            display: flex;
            align-items: center;

            .close-detail {
                transform: rotate(45deg);
                font-size: 28pt;
                margin-left: 8px;
                color: var(--button-secondary);
                cursor: pointer;
            }
        }

        &.wrapped {
            flex-direction: column-reverse;
            align-items: baseline;

            .detail-card-actions {
                align-self: flex-end;
                padding-bottom: 10px;
            }
        }
    }

    .detail-body {
        display: flex;
        flex-direction: column;

        small {
            margin-top: 20px;
            margin-bottom: 5px;
        }

        h4 {
            font-size: 14pt;
            font-weight: 600;

            &.currency {
                font-size: 18pt;
                font-family: 'Reddit Mono', monospace;
            }
        }

        .eligible-check {
            display: flex;
            align-items: center;

            svg {
                font-size: 24pt;
                margin-right: 5px;
                color: var(--shoebox-yellow);

                &.ineligible {
                    color: var(--button-secondary);
                    margin-left: -7px;
                }
            }
        }

        .insurance-label {

        }

        .insurance-asset {
            height: 25px;
            width: fit-content;

            &.medium {
                height: 34px;
            }

            &.small {
                height: 38px;
            }
        }

        .custom-insurance-text {
            font-size: 14pt;
            font-weight: 500;
            color: var(--luvly-lavendar);
        }

        .tags-container {
            sl-badge {
                margin-right: 6px;
                margin-bottom: 6px;
            }
        }

        .notes-section-label {
            margin-top: 14px;
        }

        .no-notes-text {
            color: var(--text-color-tertiary);
            font-style: italic;
        }

        .receipts-section {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-size: 12pt;
                font-weight: 400;
                color: var(--text-color-secondary);
            }

            .receipts-toggle {
                font-weight: 500;
                color: var(--shoebox-yellow);
                margin-right: 2px;
                cursor: pointer;
            }
        }

        .reciepts-container {

            img {
                width: 100%;
                margin-bottom: 12px;
            }

            .pdf-thumbnail-wrapper {
                width: 100%;
                margin-bottom: 12px;
            }

            &:has(img, .pdf-thumbnail-wrapper) :last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media only screen and (max-width: 740px) {
    .expense-detail {
        padding: 15px 15px 25px 15px;
        margin-bottom: 80px;

        .detail-header {
            h3 {
                font-size: 22pt;
            }
        }
    }
}

.desktop-detail-view {
    display: flex;
    position: fixed;
    height: calc(100dvh - 30px);
    grid-template-columns: 50% 50%;
    margin-top: -50px;
    width: calc(100% - 50px);

    .expense-detail {
        margin-top: 175px;
        // 232px = 170 (top margin) + 50 (t&b tile padding) + 12 (spacing margin below card along page bottom)
        max-height: calc(100% - 232px);
        width: calc(50% - 31px);
        max-width: 425px;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none; 
    }

    .expense-detail::-webkit-scrollbar {
        display: none;
    }
}

@media only screen and (max-width: 740px) {
    .expense-header {
        width: calc(100% - 30px);
    }
}

.expenses-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;

    span {
        margin-top: 15px;
        color: var(--text-color-secondary);
        animation: fade-in-sub-text 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation-delay: 0.7s;
        @keyframes fade-in-sub-text {
            0% {
                transform: translateY(10px);
                will-change: filter;
                filter: blur(20px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                will-change: filter;
                filter: blur(0px);
                opacity: 1;
            }
        } 
    }
}

.empty-expenses {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65vh;
    animation: fade-in-splash 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

    @keyframes fade-in-splash {
        0% {
          -webkit-transform: translateY(20px);
                  transform: translateY(20px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      

    svg {
        font-size: 40pt;
        color: var(--shoebox-yellow);
        margin-bottom: 10px;
    }

    h2 {
        font-weight: 500;
    }

    span {
        color: var(--text-color-secondary);
        margin-top: 5px;
        margin-bottom: 25px;
    }
}