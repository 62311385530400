.about-page {
    .about-page-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: fit-content;
        max-width: 100%;
        height: 50vh;
        min-height: 400px;
        padding-bottom: 100px;
        margin: auto;
        
        img {
            width: 75px;
        }

        h2 {
            font-size: 32pt;
            font-weight: 600;
            margin: 15px 0 6px 0;
        }

        sl-badge.shoebox-version-badge::part(base) {
            color: var(--text-color-secondary);
            font-family: 'Reddit Mono', monospace;
            transition: all 0.3s ease;
            cursor: pointer;

            body.hasHover &:hover {
                color: var(--text-color-main);
                background-color: var(--tile-background);
            }
        }

        .made-by-line {
            display: flex;
            align-items: center;
            width: fit-content;
            white-space: nowrap;
            margin-top: 25px;
            margin-bottom: 12px;
            color: var(--text-color-secondary);

            svg {
                font-size: 18pt;
                padding: 0 5px;
                color: var(--shoebox-error);
            }

            img {
                height: 32px;
                width: 32px;
                padding-left: 5px;
            }
        }

        .blocks-copyright {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            span {
                color: var(--text-color-secondary);
                display: flex;
                align-items: center;
                margin-bottom: 5px;

                svg {
                    font-size: 14pt;
                    padding: 0 5px;
                    // padding-bottom: 2px;
                }
            }
        }
    }
}