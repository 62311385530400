#shoebox-stats {
    display: flex;
    flex-direction: column;

    .loading-shoebox-stats {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: calc(100% - 38px);
    }

    .tile-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .toggle-wrapper {
            display: flex;
            align-items: center;

            span {
                color: var(--text-color-secondary);
                font-size: 12pt;
            }

            sl-switch {
                margin-left: 7px;
            }

            sl-switch#time-toggle::part(control) {
                background-color: var(--shoebox-yellow);
                border: none;
            }
        }

        @media only screen and (max-width: 450px) {
            .toggle-wrapper {
    
                span {
                    font-size: 10pt;
                }
            }
        }
    }

    .empty-stats {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: var(--text-color-secondary);
        height: calc(100% - 80px);
        text-align: center;

        svg {
            font-size: 24pt;
            color: var(--shoebox-yellow);
            margin-bottom: 10px;
        }

        .line-with-link {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            color: var(--text-color-secondary);

            a {
                color: var(--text-color-secondary);
                text-decoration: underline;
                font-weight: 500;
                margin: 0 5px;
            }
        }
    }

    .totals-section {
        margin-top: 32px;

        &.withdrawals {
            margin-top: 24px;
        }

        small {
            display: flex;
            align-items: center;

            span {
                display: flex;
                align-items: center;
                margin-left: 5px;

                svg {
                    font-size: 14pt;
                    cursor: pointer;
                    color: var(--tile-info-icon);
                }
            }

            
        }
    }

    .totals-section {
        small {
            margin-bottom: 0px;
        }

        h3 {
            font-size: 32pt;
            font-family: "Reddit Mono", monospace;
            font-weight: 500;
        }
    }

    #stats-info-dialog {
        div:first-of-type {
            margin-bottom: 25px;
        }
        h4 {
            display: flex;
            align-items: center;
            font-size: 14pt;
            font-weight: 600;
            margin-bottom: 10px;

            svg {
                font-size: 15pt;
                margin-right: 5px;
                margin-bottom: 1px;
                color: var(--tile-info-icon);
            }
        }

        p {
            color: var(--text-color-secondary);
            margin-bottom: 10px;

            a {
                color: var(--shoebox-yellow);
                font-weight: 500;
                cursor: pointer;
            }
        }

        p:last-of-type {
            margin-bottom: 0px;
        }
    }
}

@media only screen and (max-width: 450px) {
    #shoebox-stats {

        .totals-section {
    
            h3 {
                font-size: 24pt;
            }
        }
    }
}