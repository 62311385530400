.release-notes-page {

    h1 {
        font-family: 'Reddit Mono', monospace;
        font-weight: 700;
    }

    .notes-loader {
        width: 100%;
        max-width: 800px;
        display: flex;
        justify-content: center;
    }

    .notes-wrapper {
        max-width: 800px;

        .individual-note:not(:last-child) {
            margin-bottom: 30px;
        }

        .note-header {
            font-family: 'Reddit Mono', monospace;
            font-weight: 600;
            font-size: 16pt;
            margin-bottom: 15px;
            display: flex;
            align-items: center;

            .version-chip {
                font-size: 12pt;
                background-color: var(--badge-primary);
                color: var(--consistent-main);
                font-family: 'Reddit Mono', monospace;
                font-weight: 600;
                padding: 5px 10px;
                border-radius: 10px;
                margin-right: 10px;
            }
        }

        p, ul, ol {
            font-size: 12pt;
            margin: 0;
            margin-bottom: 5px;
            
        }

        ul, ol {
            padding-inline-start: 12px;
        }

        li {
            margin-bottom: 3px;
        }

        strong {
            font-weight: 600;
        }

        h3 {
            font-weight: 800;
            margin-top: 20px;
            margin-bottom: 10px;
            font-family: 'Reddit Mono', monospace;
        }
    }
}