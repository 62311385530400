.expense-list {
    width: 100%;
    max-width: 550px;
    margin-top: 120px;
    overflow: scroll;
    padding-right: 25px;
    -ms-overflow-style: none;
    scrollbar-width: none; 

    &.animated {
        animation: fade-in-expense-list 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @keyframes fade-in-expense-list {
            0% {
                -webkit-transform: translateY(10px);
                transform: translateY(10px);
                opacity: 0;
                will-change: filter;
                filter: blur(5px);
            }
            100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                opacity: 1;
                will-change: filter;
                filter: blur(0);
            }
        }
    }
}

.desktop-detail-view {
    .expense-list {
        // don't ask me how this value is calculated :/ it stops jitter when selected expense with detail panel appearing
        width: calc(50% - 43px);
    }
}

.expense-list::-webkit-scrollbar {
    display: none;
}

.year-block {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
}

.year-header {
    font-size: 22pt;
    font-weight: 900;
    height: 40px;
    padding-top: 10px;
    padding-bottom: 6px;
    background-color: var(--background-color);
    color: var(--text-color-main);
    display: flex;
    position: sticky;
    top: 0px;
    width: 100%;
    max-width: 550px;
    z-index: 50;
}

.expense-item {
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 12px;
    border-radius: 15px;
    background-color: var(--tile-background);
    transition: background-color 0.3s ease;
    cursor: pointer;

    .expense-summary {
        margin-left: 10px;

        h3 {
            font-size: 14pt;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        h4 {
            font-size: 11pt;
            font-weight: 400;
            margin-top: 2px;
            white-space: nowrap;
            display: flex;
            align-items: center;

            .status-icons-block {
                display: flex;
                align-items: center;

                svg {
                    font-size: 14pt;
                    margin: 0 -2px;

                    &.withdrawn {
                        color: var(--shoebox-error);
                    }
                }
            }

            .divider {
                padding: 0 5px;
                color: var(--button-secondary);
            }
        }
    }

    .expense-amount {
        display: flex;
        align-items: center;
        background: radial-gradient(var(--tile-background), var(--tile-background-transparent));
        height: 100%;
        padding-left: 12px;
        font-family: 'Reddit Mono', monospace;
        font-size: 14pt;
        transition: background 0.3s ease;

        svg {
            font-size: 22pt;
            margin-bottom: 2px;
            margin-left: 5px;
            color: var(--button-secondary);
            transition: transform 0.3s ease;
        }
    }

    body.hasHover &:hover {
        background-color: var(--tile-hover);

        .expense-amount {
            background: radial-gradient(var(--tile-hover), var(--tile-hover-transparent));
    
            svg {
                transform: translateX(5px);
            }
        }
    }

    &.selected {
        background-color: var(--shoebox-yellow);
        color: var(--consistent-main);

        .expense-summary, .expense-amount {
            color: var(--consistent-main);

            h3, h4, svg, span:not(span.withdrawn-text) {
                color: var(--consistent-main);
            }

            .withdrawn-divider {
                padding: 0 7px;
                color: var(--consistent-main);
            }
        }

        .expense-amount {
            background: radial-gradient(var(--shoebox-yellow), var(--shoebox-yellow-transparent));
        }

        body.hasHover &:hover {
            background-color: var(--shoebox-yellow);
            color: var(--consistent-main);

            .expense-amount {
                background: radial-gradient(var(--shoebox-yellow), var(--shoebox-yellow-transparent));
            }
        }
    }
}

@media only screen and (max-width: 950px) {
    .expense-list {
        padding-right: 15px;
    }
    
    .desktop-detail-view {
        .expense-list {
            // don't ask me how this value is calculated :/ it stops jitter when selected expense with detail panel appearing
            width: calc(50% - 33px);
        }
    }
}

@media only screen and (max-width: 740px) {
    .expense-list {
        margin-top: 140px;
        position: absolute;
        top: 0;
        width: calc(100vw - 30px);
        height: fit-content;
        min-height: calc(100vh - 140px);
        overflow: inherit;

        .year-block:last-of-type {
            padding-bottom: 30px;
        }
    }

    .year-header {
        position: sticky;
        top: 130px;
    }

}