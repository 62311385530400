.feedback-page {

    .feedback-widget-wrapper {
        margin-top: 150px;

        :root {
            button.primary {
                span.buttonLabel {
                    color: black !important;
                }
            }
        }
        
    }

    .title {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 12px;
        top: 70px;
        width: calc(100% - 50px);
        background-color: var(--background-color);
        z-index: 98;
        max-width: 1250px;

        h1 {
            margin: 0;
        }
    }

    .feedback-form {
        margin-top: 150px;
        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: 25px;
        width: calc(100% - 25px);
        max-width: 1025px;

        .input-with-label {
            display: flex;
            flex-direction: column;
            margin-bottom: 25px;

            label {
                margin-bottom: 10px;
                display: flex;
                align-items: center;

                .optional-indicator {
                    background-color: var(--sl-color-info-600);
                    padding: 2px 10px;
                    margin-left: 12px;
                    font-size: 12pt;
                    border-radius: 7px;
                    color: var(--text-color-main);
                }            
            }

            sl-radio-group.severity-selections {
                width: 100%;
            }

            .severity-selections::part(button-group) {
                width: 100%;
            }

            .severity-option {
                width: 100%;

                &::part(button) {
                    width: 100%;
                }
            }

            .split-function {
                display: flex;
                align-items: center;

                .yes-no-btn-group {
                    margin-right: 12px;

                    sl-radio-button::part(button) {
                        min-width: 65px;
                    }
                }

                sl-button {
                    width: 100%;
                }

                sl-radio-group.follow-up-src-btn-group {
                    width: 100%;

                    sl-radio-button::part(button) {
                        min-width: 65px;
                    }
                }

                .follow-up-src-btn-group::part(button-group) {
                    width: 100%;
                }

                .follow-up-src-option {
                    width: 100%;

                    &::part(button) {
                        width: 100%;
                    }
                }
            }

            .feedback-notes-box {
                height: 200px;
            }
        }

        .submit-feedback-button {
            margin-top: 12px;
            width: 100%;
        }
    }

    @media only screen and (max-width: 740px) {
        .title {
            width: calc(100% - 30px);
        }

        .feedback-form {
            margin: auto;
            margin-top: 150px;
            margin-bottom: 100px;
            display: block;
            width: 100%;
            max-width: 500px;
        }
    }
}