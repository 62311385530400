.onboarding-container {
    h1 {
        text-align: center;
        font-size: 48pt;
        margin-top: 100px;
        animation: fade-in-welcome 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @keyframes fade-in-welcome {
            0% {
                -webkit-transform: translateY(15px);
                transform: translateY(15px);
                opacity: 0;
                will-change: filter;
                filter: blur(5px);
            }
            100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                opacity: 1;
                will-change: filter;
                filter: blur(0);
            }
        }
    }

    .loading-checkout-block {
        padding: 20px 0;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        animation: fade-in-loading-checkout 0.7s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation-delay: 0.7s;

        @keyframes fade-in-loading-checkout {
            0% {
                -webkit-transform: translateY(15px);
                transform: translateY(15px);
                opacity: 0;
                will-change: filter;
                filter: blur(5px);
            }
            100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                opacity: 1;
                will-change: filter;
                filter: blur(0);
            }
        }

        span {
            font-size: 16pt;
            font-weight: 300;
            font-style: italic;
            margin-top: 15px;

            &.subtext {
                font-size: 14pt;
                font-style: normal;
                color: var(--text-color-secondary);
                margin-top: 10px;
            }
        }
    }

    .manual-checkout-block {
        text-align: center;
        padding: 20px 0;
        animation: fade-in-blocked-checkout 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation-delay: 0.7s;

        @keyframes fade-in-blocked-checkout {
            0% {
                -webkit-transform: translateY(5px);
                transform: translateY(5px);
                scale: 0.99;
                opacity: 0;
                will-change: filter;
                filter: blur(10px);
            }
            100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                scale: 1;
                opacity: 1;
                will-change: filter;
                filter: blur(0);
            }
        }

        span {
            display: block;
            margin-bottom: 10px;
            font-size: 16pt;
            font-weight: 300;

            svg {
                font-size: 24pt;
            }
        }

        sl-button {
            margin-top: 15px;
        }
    }

    .intro-cards-block {

        .exit-options {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 25px;
            animation: fade-in-exit-options 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation-delay: 0.7s;

            @keyframes fade-in-exit-options {
                0% {
                    -webkit-transform: translateY(5px);
                    transform: translateY(5px);
                    opacity: 0;
                    will-change: filter;
                    filter: blur(5px);
                }
                100% {
                    -webkit-transform: translateY(0);
                    transform: translateY(0);
                    opacity: 1;
                    will-change: filter;
                    filter: blur(0);
                }
            }

            a {
                font-weight: 500;
                color: var(--shoebox-yellow);
                margin: 0 20px;
                cursor: pointer;

                &.secondary {
                    color: var(--shoebox-error);
                }
            }
        }

        .support-text {
            font-size: 16pt;
            font-weight: 300;
            text-align: center;
            display: block;
            animation: fade-in-support-text 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation-delay: 0.9s;

            @keyframes fade-in-support-text {
                0% {
                    -webkit-transform: translateY(5px);
                    transform: translateY(5px);
                    opacity: 0;
                    will-change: filter;
                    filter: blur(5px);
                }
                100% {
                    -webkit-transform: translateY(0);
                    transform: translateY(0);
                    opacity: 1;
                    will-change: filter;
                    filter: blur(0);
                }
            }
        }

        .button-container {
            width: 100%;
            max-width: 700px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 25px auto;
            animation: fade-in-section-buttons 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation-delay: 1.1s;

            @keyframes fade-in-section-buttons {
                0% {
                    -webkit-transform: translateY(5px);
                    transform: translateY(5px);
                    opacity: 0;
                    will-change: filter;
                    filter: blur(5px);
                }
                100% {
                    -webkit-transform: translateY(0);
                    transform: translateY(0);
                    opacity: 1;
                    will-change: filter;
                    filter: blur(0);
                }
            }

            sl-button {
                width: 30%;
            }
        }

        .section-overview-card {
            background-color: var(--tile-background);
            padding: 25px;
            border-radius: 25px;
            animation: fade-in-section-overview-card 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation-delay: 1.3s;

            @keyframes fade-in-section-overview-card {
                0% {
                    -webkit-transform: translateY(5px);
                    transform: translateY(5px);
                    opacity: 0;
                    will-change: filter;
                    filter: blur(5px);
                }
                100% {
                    -webkit-transform: translateY(0);
                    transform: translateY(0);
                    opacity: 1;
                    will-change: filter;
                    filter: blur(0);
                }
            }

            .section-content-wrapper {
                &#expenses {
                    animation: fade-in-expenses-section 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

                    @keyframes fade-in-expenses-section {
                        0% {
                            -webkit-transform: translateY(5px);
                            transform: translateY(5px);
                            opacity: 0;
                        }
                        100% {
                            -webkit-transform: translateY(0);
                            transform: translateY(0);
                            opacity: 1;
                        }
                    }
                }

                &#portfolio {
                    animation: fade-in-portfolio-section 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

                    @keyframes fade-in-portfolio-section {
                        0% {
                            -webkit-transform: translateY(5px);
                            transform: translateY(5px);
                            opacity: 0;
                        }
                        100% {
                            -webkit-transform: translateY(0);
                            transform: translateY(0);
                            opacity: 1;
                        }
                    }
                }

                &#profile {
                    animation: fade-in-profile-section 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

                    @keyframes fade-in-profile-section {
                        0% {
                            -webkit-transform: translateY(5px);
                            transform: translateY(5px);
                            opacity: 0;
                        }
                        100% {
                            -webkit-transform: translateY(0);
                            transform: translateY(0);
                            opacity: 1;
                        }
                    }
                }

                h2 {
                    font-size: 32pt;
                    margin-bottom: 15px;
                }
    
                .section-content {
    
                    h3 {
                        font-size: 22pt;
                        font-weight: 300;
                        margin-bottom: 5px;

                        &.secondary {
                            font-size: 16pt;
                            font-weight: 400;
                            font-family: 'Reddit Mono', monospace;
                            text-transform: uppercase;
                        }
                    }
    
                    .description {
                        font-size: 16pt;
                        color: var(--text-color-secondary);
                        max-width: 740px;
                        margin-bottom: 25px;
                    }
    
                    .feature-tiles {
                        display: grid;
                        grid-template-columns: 40% 30% 30%;
                        padding-top: 5px;

                        &#portfolio-tiles {
                            grid-template-columns: 34% 33% 33%;
                        }

                        &#profile-tiles {
                            grid-template-columns: 32% 32% 36%;
                        }
    
                        .feature-card {
                            h4 {
                                font-size: 16pt;
                                font-weight: 600;
                                display: flex;
                                align-items: center;
    
                                svg {
                                    font-size: 18pt;
                                    color: var(--shoebox-yellow);
                                    background-color: var(--consistent-main);
                                    border-radius: 50px;
                                    margin-right: 5px;
                                }
                            }

                            .feature-support-text {
                                font-size: 12pt;
                                color: var(--text-color-secondary);
                                padding-right: 15px;
                                margin-bottom: 15px;
                                margin-top: 10px;

                                svg {
                                    margin-bottom: -2.5px;
                                }

                                .w-icon {
                                    color: var(--shoebox-error);
                                }

                                &.with-button-img {
                                    display: flex;
                                    align-items: center;
                                    margin-top: 25px;

                                    img {
                                        height: 45px;
                                        margin: 0 7px;
                                    }
                                }
                            }
    
                            .expense-form-img {
                                background-color: var(--background-color);
                                padding: 15px;
                                padding-bottom: 20px;
                                border-radius: 15px;
                                width: calc(100% - 75px);
                                max-width: 300px;
                                margin: auto;
                            }
    
                            .invoice-image {
                                width: calc(100% - 50px);
                                max-width: 300px;
                                border-radius: 10px;
                            }

                            .fw-image {
                                width: calc(100% - 25px);
                                max-width: 350px;
                                border-radius: 10px;
                            }
                        }
                    }

                    @media only screen and (max-width: 1200px) {
                        .feature-tiles {
                            grid-template-columns: 1fr;
                            max-width: 700px;
                            margin: auto;
                            text-align: center;

                            h4 {
                                justify-content: center;
                            }
    
                            &#portfolio-tiles {
                                grid-template-columns: 1fr;
                            }
    
                            &#profile-tiles {
                                grid-template-columns: 1fr;
                            }

                            .feature-card:not(:last-child) {
                                padding-bottom: 25px;
                            }

                            .feature-support-text.with-button-img {
                                margin-top: 25px;
                                justify-content: center;
                            }

                            img {
                                display: block;
                            }

                            .invoice-image, .fw-image {
                                margin: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 650px) {
        .intro-cards-block {
            .button-container {
                sl-button {
                    width: 32%;
                }
            }
        }
    }

    @media only screen and (max-width: 550px) {
        .intro-cards-block {
            .button-container {
                display: grid;
                grid-template-columns: 50% 50%;
                width: calc(100% - 15px);
                margin: 25px 0;
                column-gap: 15px;
                row-gap: 15px;

                .full-width {
                    grid-column: 1 / -1; /* Make it span across both columns */
                }

                sl-button {
                    width: 100%;
                }
            }

            .section-overview-card {
                border-radius: 15px;

                h2 {
                    font-size: 26pt;
                }
            }
        }
    }

    @media only screen and (max-width: 450px) {
        .intro-cards-block {
            .exit-options {
                flex-direction: column;

                a:first-of-type {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

@media only screen and (max-width: 550px) {
    .onboarding-container {
        h1 {
            font-size: 36pt;
        }
    }
}