#account-security {
    .page-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 500px;

        label {
            margin-bottom: 10px;
        }

        h2 {
            font-weight: 600;
            margin-bottom: 10px;
        }

        .email-update, .password-update {
            margin-bottom: 15px;
            display: flex;
            align-items: center;

            a {
                font-weight: 500;
                color: var(--shoebox-yellow);
                margin-left: 12px;
                cursor: pointer;
            }
        }

        svg {
            font-size: 22pt;
        }

        .email-update-wrapper {
            display: grid;
            grid-template-rows: 0fr;
            overflow: hidden;
            margin-bottom: 15px;
            padding: 0px;
            opacity: 0;
            will-change: filter;
            filter: blur(3px);
            transition: grid-template-rows 0.3s ease, margin 0.3s ease, padding 0.3s ease, opacity 0.3s ease, filter 0.3s ease;

            &.expanded {
                grid-template-rows: 1fr;
                margin-bottom: 30px;
                padding: 12px 0;
                opacity: 1;
                will-change: filter;
                filter: blur(0px);
            }
        }

        .email-update-form {
            min-height: 0;
            display: flex;
            flex-direction: column;

            .buttons {
                display: flex;
                margin-top: 15px;

                sl-button:not(:last-of-type) {
                    margin-right: 12px;
                }
            }

            
            
            span {
                font-size: 14pt;
                color: var(--text-color-main);

                a {
                    color: var(--shoebox-yellow);
                }
            }
        }

        .password-update-wrapper {
            display: grid;
            grid-template-rows: 0fr;
            overflow: hidden;
            margin-bottom: 15px;
            padding: 0px 2px;
            opacity: 0;
            will-change: filter;
            filter: blur(3px);
            transition: grid-template-rows 0.3s ease, margin 0.3s ease, padding 0.3s ease, opacity 0.3s ease, filter 0.3s ease;

            &.expanded {
                grid-template-rows: 1fr;
                margin-bottom: 30px;
                padding: 12px 2px;
                opacity: 1;
                will-change: filter;
                filter: blur(0px);
            }
        }

        .password-update-form {
            min-height: 0;

            .input-wrapper {
                position: relative;

                input {
                    padding-right: 45px;
                }
            }
            
            .password-toggle {
                color: var(--text-color-secondary);
                position: absolute;
                right: 16px;
                top: 14.355px;
                cursor: pointer;

                svg {
                    font-size: 16pt;
                }
            }

            div {
                display: flex;
                flex-direction: column;

                input {
                    margin-bottom: 20px;
                }
            }

            .password-form-controls {
                display: grid;
                grid-template-columns: 50% 50%;
                width: calc(100% - 12px);
                column-gap: 12px;
            }
        }
    }
}