#annual-progress-tile {
    position: relative;

    .annual-progress-loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;

        .tile-header-loading {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;

            h2 {
                font-weight: 600;
                font-size: 22pt;
            }

            svg {
                font-size: 28pt;
                color: var(--tile-button-background);
                margin-right: -10px;
            }
        }
    }

    .tile-header {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        small.help-link {
            cursor: pointer;
            text-decoration: underline;
        }

        svg {
            font-size: 28pt;
            color: var(--tile-button-background);
            margin-right: -10px;

            &.complete {
                color: var(--shoebox-yellow);
                animation: swirl-in-complete 0.5s ease-out both;

                @keyframes swirl-in-complete {
                    0% {
                        -webkit-transform: rotate(540deg) scale(.2);
                        transform: rotate(540deg) scale(.2);
                        opacity: 0;
                    }
                    50% {
                        -webkit-transform: scale(2);
                        transform: scale(2);
                    }
                    100% {
                        -webkit-transform: rotate(0) scale(1);
                        transform: rotate(0) scale(1);
                        opacity: 1;
                    }
                }
            }

            &.alert-warning {
                color: var(--shoebox-error);
            }
        }

        @media only screen and (max-width: 500px) {
            svg {
                margin-right: -5px;
                margin-top: -3px;
            }
        }

        #excessive-contribution-popup {
            h4 {
                display: flex;
                align-items: center;
                font-size: 14pt;
                font-weight: 600;
                margin-bottom: 6px;
                margin-top: 10px;
        
                svg {
                    font-size: 15pt;
                    margin-right: 5px;
                    margin-bottom: 1px;
                    color: var(--tile-info-icon);
        
                    &.overflow {
                        font-size: 17pt;
                    }
                }
            }
        
            h4:first-of-type {
                margin-top: 0;
            }
        
            p {
                color: var(--text-color-secondary);
                padding-bottom: 10px;
                display: block;
        
                .link {
                    color: var(--shoebox-yellow);
                    font-weight: 500;
                    cursor: pointer;
                }
            }
        
            p:last-of-type {
                padding-bottom: 0px;
            }
        }
    }

    canvas {
        position: absolute;
        bottom: 0;
    }

    .progress-text-wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        bottom: 0;
        left: 50%;
        width: max-content;
        transform: translateX(-50%);

        .progress-text, .completed-text {
            display: flex;
            flex-direction: column;
            align-items: center;

            small {
                margin-bottom: 5px;
            }

            h3 {
                font-size: 32pt;
                font-family: "Reddit Mono", monospace;
                font-weight: 500;

                &.error {
                    color: var(--shoebox-error);
                }
            }
        }
        
        .progress-text {
            small {
                margin-bottom: 0;

                .figures {
                    color: var(--text-color-secondary);
                    font-family: "Reddit Mono", monospace;
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    #annual-progress-tile {
    
        canvas {
            padding: 0 10px;
        }
    }
}

@media only screen and (max-width: 450px) {
    #annual-progress-tile {

        .progress-text-wrapper {

            .progress-text, .completion-text {
                text-align: center;

                h3 {
                    font-size: 24pt;
                }
            }
        }
    }
}