html, body {
    margin: 0;
    padding: 0;
    border: 0;
    overflow-x: hidden;
    position: relative; 
    scroll-behavior: smooth;
    background-color: var(--background-color);
    // removal of mobile safari grey outline onClick
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: 'Inter', sans-serif;
    scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

body {
    padding: 10px 25px 50px 25px;
    min-height: calc(100vh - 60px);
    max-width: 1250px;
    margin: auto;
}

@media only screen and (max-width: 740px) {
    body {
        padding: 10px 15px 50px 15px;
    }
}

.content {
    margin-top: 70px;
    min-height: calc(100vh - 500px);
}

h1, h2, h3, h4, h5, h6, p, span, div {
    font-family: 'Inter', sans-serif;
    margin: 0;
    color: var(--text-color-main);
}

h1 {
    font-size: 32pt;
    margin-bottom: 25px;
}

a {
    text-decoration: none;
    font-family: 'Inter', sans-serif;
}

label {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 14pt;
    color: var(--text-color-main);
}

small {
    font-size: 12pt;
    font-weight: 400;
    margin-bottom: 10px;
    color: var(--text-color-secondary);
}

input, textarea {
    height: 50px;
    border-radius: 15px;
    font-size: 14pt;
    font-family: 'Inter', sans-serif;
    border: none;
    padding: 0 16px;
    background-color: var(--tile-background);
    color: var(--text-color-main);
    resize: none;
}

input:disabled, textarea:disabled {
    cursor: not-allowed;
    color: var(--text-color-disabled);
}

textarea {
    padding: 12px 16px;
    height: 100px;
}

input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
}

input:focus, textarea:focus {
    outline: 2px solid var(--shoebox-yellow);
}

::-webkit-calendar-picker-indicator {
    cursor: pointer;
}

.sl-theme-shoebox-dark {
    ::-webkit-calendar-picker-indicator {
        filter: invert(0.9);
    }
}



.blocks-currency-input {
    position: relative;

    // hides number arrows
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    input[type=number] {
    -moz-appearance: textfield;
    }

    span {
        position: absolute;
        top: 50%;
        padding-left: 16px;
        transform: translateY(-50%);
        margin: auto;
        font-size: 14pt;
        color: var(--text-color-main);

        &.disabled {
            color: var(--text-color-disabled);
            cursor: not-allowed;
        }
    }

    input {
        padding-left: 22pt;
    }
}

#blocks-icon-button::part(label) {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#blocks-icon-button::part(base) {
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;

}

#blocks-icon-button {

    svg {
        font-size: 24pt;
        margin-top: 7px;
        margin-bottom: -2px;
        color: var(--text-color-main);

        &#key {
            transform: scale(1.5);
        }
    }

    span {
        font-weight: 400;
        margin: 0;
        padding: 0;
        font-size: 13pt;

        &.external-link {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                font-size: 14pt;
                margin: 0;
                padding-left: 3px;
                color: var(--text-color-main) !important;
            }
        }
    }

    &.expense {
        svg {
            font-size: 20pt;
        }
    }

    &.disabled {
        svg, span {
            color: var(--text-color-disabled);
        }
    }
}

#blocks-icon-button[variant="primary"] {

    svg {
        color: var(--consistent-black);
    }

    span {
        color: var(--consistent-black);
    }
}

.ptr--ptr {
    z-index: 99;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--shoebox-yellow);
    box-shadow: none !important;
}

.ptr--text {
    font-family: 'JetBrains Mono', monospace;
    color: var(--text-color-main) !important;
}

.ptr--icon {
    font-size: 22pt;
    color: var(--text-color-main) !important;
}


// GLOBAL
@import './LocalPalette.scss';
@import './components/global/Header.scss';
@import './components/global/Footer.scss';
@import './components/global/About.scss';
@import './components/global/ReleaseNotes.scss';
@import './components/global/Loader.scss';
@import './components/global/Legal/Legal.scss';
@import './components/global/FAQ.scss';
@import './shoebox-light.css';
@import './shoebox-dark.css';

// UNAUTHENTICATED
@import './components/external/Landing.scss';
@import './components/external/Pricing.scss';
@import './components/external/CreateAccount.scss';
@import './components/external/Notification.scss';
@import './components/authentication/SignUp.scss';
@import './components/authentication/SignIn.scss';
@import './components/authentication/ForgotPassword.scss';

// SETUP & ONBOARDING
@import './components/authentication/Onboarding.scss';

// EXPENSES
@import './components/expenses/Expenses.scss';
@import './components/expenses/ExpenseForm.scss';
@import './components/expenses/ExpensesList.scss';

// PORTFOLIO
@import './components/portfolio/Portfolio.scss';
@import './components/portfolio/Configuration.scss';
@import './components/portfolio/tiles/Tiles.scss';
@import './components/portfolio/tiles/AccountValue/AccountValue.scss';
@import './components/portfolio/tiles/AnnualProgress/AnnualProgress.scss';
@import './components/portfolio/tiles/ShoeboxStats/ShoeboxStats.scss';
@import './components/portfolio/tiles/Contributions/Contributions.scss';
@import './components/portfolio/tiles/SavingsAnalysis/SavingsAnalysis.scss';
@import './components/portfolio/tiles/Ticker/Ticker.scss';
@import './components/portfolio/ContributionForm.scss';
@import './components/portfolio/tiles/Contributions/ContributionSchedule.scss';
@import './components/portfolio/Investments.scss';

// PROFILE
@import './components/profile/Profile.scss';
@import './components/profile/AccountSecurity.scss';
@import './components/profile/PersonalInfo.scss';
@import './components/profile/Subscription.scss';
@import './components/global/404.scss';

// RESOURCES & SUPPORT
@import './components/global/About.scss';
@import './components/global/Feedback.scss';
@import './components/global/Support.scss';
@import './components/global/HelpDocs.scss';


body, html {
    font-family: sans-serif;

    /* annoying hover ascent-override */
    --sl-color-primary-100: var(--sl-color-primary-500);
    --sl-color-primary-50: var(--sl-color-primary-500);

    // Input focus overries
    --sl-focus-ring-offset: 0px;
    --sl-focus-ring: 2px solid var(--sl-color-primary-500);
}

/* V V Custom Blocks Overrides for Shoelace Components V V */

/* INPUT */

sl-input::part(base) {
    height: 50px;
    display: flex;
    font-size: 14pt;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

sl-input[filled]::part(base) {
    background-color: var(--sl-color-info-500);
}

sl-input::part(password-toggle-button) {
    font-size: 16pt;
}

sl-input::part(form-control-label){
    font-weight: 500;
    font-size: 14pt;
    margin-bottom: 10px;
    color: var(--sl-color-black);
}

sl-input::part(input) {
    color: var(--text-color-main);
}


/* TEXT AREA  */

sl-textarea::part(base) {
    background-color: var(--sl-color-info-500);
    font-size: 14pt;
    border-radius: 15px;
}

sl-textarea::part(form-control-label){
    font-weight: 500;
    font-size: 14pt;
    margin-bottom: 10px;
    color: var(--sl-color-black);
}


/* SELECT */

sl-select::part(form-control-input) {
    height: 50px;
    border-radius: 15px;
}

sl-select::part(combobox) {
    font-size: 14pt;
    height: 50px;
    border-radius: 15px;
    background-color: var(--sl-color-info-500);
}

sl-select::part(form-control-label){
    font-weight: 500;
    font-size: 14pt;
    margin-bottom: 10px;
    color: var(--sl-color-black);
}

sl-option::part(label) {
    color: var(--sl-color-black);
}

sl-option, .option-current {
    background-color: transparent;
    transition: background-color 0.3s ease;

    body.hasHover &:hover {
        background-color: var(--tile-hover);
    }
}

sl-option::part(base), .option--current {
    background-color: transparent;
}

sl-option::part(checked-icon) {
    margin-right: 10px;
    color: var(--shoebox-yellow);
}


/* BUTTON  */

sl-button::part(label) {
    font-size: 14pt;
    padding: 0 25px;
    color: var(--consistent-black);
}

sl-button::part(base) {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 15px;
    color: var(--consistent-black);
}

sl-button[variant="default"]::part(label) {
    color: var(--consistent-black);
}

sl-button[variant="primary"]::part(label) {
    color: var(--consistent-black);
}

sl-button[variant="default"]::part(base) {
    background-color: var(--sl-color-primary-500);
    border-radius: 15px;
    transition: background-color 0.4s ease;
}

sl-button[variant="primary"]::part(base) {
    background-color: var(--sl-color-primary-500);
    border-radius: 15px;
    transition: background-color 0.4s ease;
}

sl-button[variant="default"]::part(base) {
    background-color: var(--sl-color-primary-500);
    border-radius: 15px;
    transition: background-color 0.4s ease;
}

sl-button[disabled]::part(base) {
    background-color: var(--button-background-disabled);
    opacity: 1;

    body.hasHover &:hover {
        background-color: var(--button-background-disabled);
    }
}

sl-button[disabled]::part(label) {
    color: var(--button-primary-text-disabled);
    opacity: 1;
}

sl-button[variant="neutral"][disabled]::part(label) {
    color: var(--button-text-disabled);
}

sl-button[variant="neutral"]::part(label) {
    color: var(--sl-color-black);
}

sl-button[variant="neutral"]::part(base) {
    background-color: var(--sl-color-info-500);
    border-radius: 15px;
    transition: background-color 0.4s ease;
}

body.hasHover sl-button[variant="neutral"]::part(base):hover {
    background-color: var(--tile-hover);
    color: var(--sl-color-black);
}

body.hasHover sl-button[variant="primary"]::part(base):hover {
    background-color: var(--shoebox-hover);
    color: var(--sl-color-black);
}

body.hasHover sl-button[variant="default"]::part(base):hover {
    background-color: var(--shoebox-hover);
    color: var(--sl-color-black);
}

sl-button[variant="neutral"][disabled]::part(base) {
    body.hasHover &:hover {
        background-color: var(--sl-color-info-500);
    }
}

sl-button[variant="danger"][disabled]::part(label) {
    color: var(--sl-color-white);
    opacity: 0.3;
}

sl-button[variant="danger"]::part(label) {
    color: var(--sl-color-white);
}

sl-button[variant="danger"]::part(base) {
    background-color: var(--shoebox-error);
    border-radius: 15px;
    transition: background-color 0.4s ease;
}

body.hasHover sl-button[variant="danger"]::part(base):hover {
    background-color: var(--shoebox-error-hover);
    color: var(--sl-color-black);
}

sl-button[variant="danger"][disabled]::part(base) {
    background-color: var(--shoebox-error-disabled);
    body.hasHover &:hover {
        background-color: var(--shoebox-error-disabled);
    }
}

.blocks-pill-btn[variant="default"]::part(base) {
    background-color: var(--sl-color-primary-500);
    border-radius: 10px;
}

.blocks-pill-btn[variant="neutral"]::part(base) {
    background-color: var(--sl-color-info-500);
    border-radius: 10px;
}

.blocks-pill-btn[variant="danger"]::part(base) {
    background-color: var(--shoebox-error);
    border-radius: 10px;
}

.blocks-pill-btn[variant="neutral"]::part(label) {
    color: var(--sl-color-black);
}

.blocks-pill-btn::part(base) {
    height: 35px;
    border-radius: 10px;
    font-size: 12pt;
}

.blocks-pill-btn[disabled]::part(base) {
    background-color: var(--sl-color-primary-400);
    opacity: 1;

    body.hasHover &:hover {
        background-color: var(--sl-color-primary-400);
    }
}

.blocks-pill-btn::part(label) {
    font-size: 12pt;
    padding: 0 15px;
    font-weight: 400;
}


/* BADGE */

sl-badge::part(base) {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    font-size: 12pt;
    font-weight: 400;
    padding: 0 10px;
    padding-top: 2px;
    border-radius: 10px;
}

sl-badge[variant="primary"]::part(base) {
    background-color: var(--badge-primary);
    color: var(--consistent-black);
    border-radius: 10px;
}

sl-badge[variant="neutral"]::part(base) {
    background-color: var(--badge-neutral);
    color: var(--sl-color-black);
    border-radius: 10px;
}

sl-badge[variant="neutral-filled"]::part(base) {
    border: 1px solid var(--text-color-tertiary);
}

sl-badge[variant="small-neutral"]::part(base) {
    background-color: var(--badge-small-neutral-bg);
    color: var(--badge-small-neutral-text);
    height: 24px;
    font-size: 8pt;
    font-weight: 600;
    border-radius: 10px;
}

sl-badge[variant="small-success"]::part(base) {
    background-color: var(--badge-small-success-bg);
    color: var(--badge-small-success-text);
    height: 24px;
    font-size: 8pt;
    font-weight: 600;
    border-radius: 10px;
}

sl-badge.indicator[variant="success"]::part(base) {
    background-color: var(--success-toast-icon);
    --pulse-color: var(--badge-small-success-bg);
    width: 12px;
    height:12px;
    padding: 0;
    margin-left: 2px;
    margin-top: 2px;
    margin-right: 7px;  
}

sl-badge.indicator[variant="neutral"]::part(base) {
    background-color: var(--text-color-tertiary);
    --pulse-color: transparent;
    width: 12px;
    height:12px;
    padding: 0;
    margin-left: 2px;
    margin-top: 2px;
    margin-right: 7px;  
}


/* RADIO GROUP */

sl-radio-button::part(base) {
    height: 50px;
}

sl-radio-group::part(form-control-label) {
    font-weight: 500;
    font-size: 14pt;
    margin-bottom: 10px;
    color: var(--sl-color-black);
}

sl-radio-button::part(button) {
    border-radius: 10px;
    border: none;
    height: 36px;
    color: var(--sl-color-black);
    font-weight: 400;
    transition: background-color 0.4s ease;
    width: max-content;
    min-width: 100px;
}

sl-radio-button::part(button--checked) {
    background-color: var(--sl-color-primary-500);
    color: var(--consistent-black);
}

body.hasHover sl-radio-button::part(button):hover {
    background-color: transparent;
}

body.hasHover sl-radio-button::part(button--checked):hover {
    background-color: var(--sl-color-primary-500);
}

sl-radio-group::part(button-group) {
    background-color: var(--sl-color-info-500);
    border-radius: 15px;
    padding: 7px;
    height: 50px;
}

sl-radio-group::part(form-control-help-text) {
    margin-top: 10px;
}


/* RATING  */

sl-rating::part(base) {
    --symbol-color: var(--sl-color-info-500);
    --symbol-color-active: var(--sl-color-primary-500);
}


/* SPINNER */

sl-spinner {
    --track-width: 10px;
    font-size: 65px;
    --track-color: var(--sl-color-info-500);
    --indicator-color: var(--sl-color-primary-500);
}

sl-spinner[variant="total-account-window"] {
    --track-width: 8px;
    font-size: 32pt;
    margin: 0.18px 0;
    --track-color: var(--background-color);
    --indicator-color: var(--sl-color-primary-500);
}

sl-spinner[variant="button-primary"] {
    font-size: 18px;
    --track-width: 3px;
    --indicator-color: var(--stat-bg);
    --track-color: var(--button-spinner-indicator);
}

sl-spinner[variant="button-neutral"] {
    font-size: 24px;
    --track-width: 3px;
    --track-color: var(--background-color);
    --indicator-color: var(--sl-color-primary-500);
}

sl-spinner[variant="button-danger"] {
    font-size: 24px;
    --track-width: 3px;
    --track-color: var(--background-color);
    --indicator-color: var(--shoebox-error);
}

sl-spinner[variant="badge-neutral"] {
    font-size: 16px;
    --track-width: 3px;
    --track-color: var(--background-color);
    --indicator-color: var(--sl-color-primary-500);
}

sl-spinner[variant="search"] {
    font-size: 18px;
    --track-width: 5px;
    --track-color: var(--background-color);
    --indicator-color: var(--sl-color-primary-500);
}

sl-spinner[variant="ptr"] {
    font-size: 28px;
    --track-width: 5px;
    --track-color: var(--tile-chart-light-gradient);
    --indicator-color: var(--text-color-main);
}

/* DRAWER */

sl-drawer::part(overlay) {
    background-color: var(--overlay-background);
    will-change: filter;
    backdrop-filter: blur(5px);
    margin: 0 auto;
}

sl-drawer::part(panel) {
    box-shadow: 0px -5px 25px 0px var(--drawer-shadow);
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    max-width: 650px;
    width: 100%;
    left: 50%;
    margin-left: -325px;
    overflow: hidden;
}

@media only screen and (max-width: 650px) {
    sl-drawer::part(panel) {
        max-width: unset;
        width: 100%;
        left: unset;
        margin-left: unset;
    }
}

sl-drawer::part(header) {
    height: 75px;
}

sl-drawer::part(label) {
    width: 100%;
}

sl-drawer::part(header-actions) {
    padding: 0;
}

sl-drawer::part(close-button) {
    display: none;
}

sl-drawer::part(footer) {
    width: 100%;
}

sl-drawer::part(body) {
    padding: 0;
}

.shoebox-drawer {
    --size: 95%;

    .drawer-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        svg {
            font-size: 28pt;
            color: var(--button-secondary);
            transform: rotate(45deg);
            cursor: pointer;
            transition: color 0.4s ease;

            body.hasHover &:hover {
                color: var(--button-secondary-hover);
            }
        }

        h1 {
            display: flex;
            align-items: center;
            font-size: 24pt;
            margin: 0;
    
            .dashboard-icon {
                font-size: 28pt;
                color: var(--shoebox-yellow);
                margin-right: 10px;
            }
        }
    }

    .shoebox-drawer-action-button {
        width: 100%;
        margin-bottom: 15px;
    } 
 }

.config-popup {
   --size: 95%;

    .dismiss-config {
        width: 100%;
        margin-bottom: 15px;
    }
}

// Tab Group

sl-tab-group {
    --track-width: 10px;
    --track-color: var(--tile-background);
}

sl-tab-group::part(tabs)::before {
    content:'';
    position:absolute;
    top:0px;
    right:-15px;
    background: var(--tile-background);
    width: 20px;
    height: 10px;
    transform: rotate(-90deg);
    border-radius:0 10px 10px 0;
}

sl-tab-group[active]::part(tabs)::before {
    content:'';
    position:absolute;
    top:0px;
    right:-15px;
    background: var(--shoebox-yellow);
    width: 20px;
    height: 10px;
    transform: rotate(-90deg);
    border-radius:0 10px 10px 0;
}

sl-tab-group::part(tabs)::after {
        content:'';
        position:absolute;
        bottom:0;
        right:-15px;
        background: var(--tile-background);
        width: 20px;
        height: 10px;
        transform: rotate(90deg);
        border-radius:0 10px 10px 0;
}

sl-tab-group::part(active-tab-indicator) {
    z-index: 2;
    --indicator-color: var(--shoebox-yellow);
    border-radius: 50px;
}

sl-tab::part(base) {
    color: var(--text-color-secondary);
}

sl-tab[active]::part(base) {
    color: var(--text-color-main);
}

// TOOLTIP

sl-tooltip::part(body) {
    font-size: 10pt;
    border-radius: 10px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    background-color: var(--tile-button-background);
    color: var(--text-color-main);
    padding: 6px 12px;
}

sl-tooltip::part(base__arrow) {
    background-color: var(--tile-button-background);
}


// SWITCH

sl-switch {
    --width: 50px;
    --height: 28px;
    --thumb-size: 20px;
}

sl-switch::part(thumb) {
    background-color: var(--background-color);
}

sl-switch::part(control) {
    background-color: var(--tile-chart-background);
    border: none;
}

sl-switch[checked]::part(control) {
    background-color: var(--shoebox-yellow);
}

sl-switch[variant="button-size"] {
    --width: 90px;
    --height: 50px;
    --thumb-size: 36px;
}

sl-switch[variant="button-size"]::part(base) {
    width: fit-content;
}

sl-switch[variant="button-size"]::part(thumb) {
    background-color: var(--background-color);
}

sl-switch[variant="button-size"]::part(control) {
    background-color: var(--badge-neutral);
    border: none;
}

sl-switch[variant="button-size"][checked]::part(control) {
    background-color: var(--shoebox-yellow);
    border: none;
}

// DIALOG

sl-dialog::part(overlay) {
    background-color: var(--overlay-background);
    will-change: filter;
    backdrop-filter: blur(5px);
    margin: 0 auto;
}

sl-dialog::part(panel) {
    max-width: calc(100% - 30px);
    border-radius: 25px;
    max-height: 90vh;
}

sl-dialog::part(title) {
    font-family: 'Inter', sans-serif;
    font-size: 18pt;
    font-weight: 500;
    padding-right: 10px;
}

sl-dialog::part(header-actions) {
    padding-left: 0;
}

sl-dialog::part(body) {
    padding-top: 0px;
}


// TOAST

.sl-toast-stack {
    position: fixed;
    top: 0;
    right: 0;
    // One above shoelace drawer z-index for toasts related to saving contributions and investments
    z-index: 701;
    margin-top: 50px;
    padding: 25px;
}

sl-alert::part(close-button__base):hover {
    color: var(--text-color-main);
}

sl-alert {
    margin-bottom: 12px;
}

sl-alert::part(base) {
    border-radius: 15px;
}


sl-alert::part(message) {
    color: var(--text-color-main);
}

sl-alert[variant="success"]::part(base) {
    background-color: var(--success-toast-background);
}

sl-alert[variant="success"]::part(icon) {
    color: var(--success-toast-icon);
}

sl-alert[variant="primary"]::part(base) {
    background-color: var(--primary-toast-background);
}

sl-alert[variant="primary"]::part(icon) {
    color: var(--primary-toast-icon);
}

sl-alert[variant="update"]::part(base) {
    background-color: var(--primary-toast-background);
}

sl-alert[variant="update"]::part(icon) {
    color: var(--primary-toast-icon);
}

sl-alert[variant="danger"]::part(base) {
    background-color: var(--danger-toast-background);
}

sl-alert[variant="danger"]::part(icon) {
    color: var(--danger-toast-icon);
}


// SKELETON

sl-skeleton {
    background-color: var(--skeleton-background-color);
    --sheen-color: var(--skeleton-sheen-color);
}