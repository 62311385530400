.help-category-header {
    position: fixed;
    padding-top: 10px;
    top: 70px;
    width: calc(100% - 50px);
    max-width: 1250px;
    background-color: var(--background-color);
    z-index: 98;
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: space-between;

    sl-button {
        width: fit-content;
        margin-bottom: 15px;

        svg {
            font-size: 16pt;
            margin-right: 5px;
            margin-left: -3px;
        }
    }

    .blocks-pill-btn::part(label) {
        display: flex;
        align-items: center;

        
    }

    h1 {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 740px) {
    .help-category-header {
        width: calc(100% - 30px);
    }
}

.category-heading {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    svg {
        font-size: 32pt;
        margin-right: 7px;
        color: var(--shoebox-yellow);
    }

    h2 {
        font-size: 24pt;
    }
}

.individual-document {
    max-width: 750px;
    margin-top: 200px;

    .doc-header {
        font-size: 24pt;
        margin: 0;
    }
    .updated-chip {
        display: flex;
        align-items: center;
        color: var(--text-color-secondary);
        margin-bottom: 15px;
        margin-top: 5px;
        font-style: italic;

        sl-badge {
            margin-left: 10px;
            font-style: normal;
            font-family: 'Reddit Mono', monospace;
        }
    }

    h2 {
        margin-bottom: 10px;
    }

    h2:not(:first-of-type) {
        margin-top: 25px;
    }

    p {
        margin-bottom: 10px;
    }

    h4 {
        font-size: 14pt;
        margin-bottom: 0px;
    }
}