.faq-panel-group {
    width: 100%;
    max-width: 700px;

    sl-details.faq-panel {
        margin-bottom: 12px;
        
    }

    sl-details.faq-panel::part(base) {
        // border: 1px solid var(--badge-neutral);
        
        background-color: var(--tile-background);
        border-radius: 20px;
    }

    sl-details.faq-panel::part(summary) {
        font-size: 18pt;
        font-weight: 600;
        color: var(--text-color-main);
    }

    sl-details.faq-panel::part(summary-icon) {
        color: var(--shoebox-yellow);
        font-size: 25pt;
    }

    sl-details.faq-panel {
        p {
            font-size: 14pt;
            font-weight: 400;
            color: var(--text-color-secondary);
            margin-bottom: 20px;

            &.link {
                
                a {
                    display: flex;
                    align-items: center;
                    color: var(--shoebox-yellow);
                    cursor: pointer;
                    font-weight: 500;

                    svg {
                        font-size: 16pt;
                        margin-left: 5px;
                    }
                }
            }
        }

        p:last-of-type {
            margin-bottom: 10px;
        }
    }
}