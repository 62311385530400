.subscription-page {
    sl-button::part(label) {
        display: flex;
        align-items: center;
    }
}

.subscription-content-wrapper {
    #pricing {
        margin: 0;

        .pricing-block {
            max-width: 900px;
            padding: 35px 0;
            border-radius: 35px;
        }
    }
}

.subscription-loader {
    .subscription-card-skeleton {
        width: 650px;
        height: 200px;
        border-radius: 25px;
        --sheen-color: var(--background-color);
        --color: var(--tile-background);
    }

    sl-skeleton.subscription-card-skeleton::part(indicator) {
        border-radius: 25px;
    }

    .subscription-card-button-skeleton {
        width: 650px;
        height: 50px;
        border-radius: 15px;
        margin-top: 16px;
        --sheen-color: var(--background-color);
        --color: var(--tile-background);
    }
    
    sl-skeleton.subscription-card-button-skeleton::part(indicator) {
        border-radius: 15px;
    }

    .subscription-card-header-skeleton {
        width: 400px;
        height: 29px;
        border-radius: 10px;
        margin-bottom: 12px;
        --sheen-color: var(--tile-background);
        --color: var(--text-color-tertiary);
    }
    
    sl-skeleton.subscription-card-header-skeleton::part(indicator) {
        border-radius: 10px;
    }
}

.cancelation-notice-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    opacity: 0;
    will-change: filter;
    filter: blur(10px);
    transition: grid-template-rows 0.3s ease, margin 0.3s ease, opacity 0.3s ease, filter 0.3s ease;
    margin-bottom: 0px;

    &.expanded {
        grid-template-rows: 1fr;
        opacity: 1;
        will-change: filter;
        filter: blur(0px);
        margin-bottom: 25px;
    }
}

.cancelation-notice {
    min-height: 0;
    background-color: var(--shoebox-error);
    color: var(--background-color);
    text-align: center;
    padding: 0;
    border-radius: 15px;
    transition: padding 0.3s ease;

    &.shown {
        padding: 15px 25px;
    }
}

.subscription-status-text {
    font-weight: 400;
    margin-bottom: 12px;

    &.choose-plan {
        animation: fade-in-text 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @keyframes fade-in-text {
            0% {
                transform: translateY(-10px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
}

#plan-selection-button {
    margin-left: 12px;
    width: 217px;
}

@media only screen and (max-width: 740px) {
    #plan-selection-button {
        width: unset;
    }
}

.checkout-button {
    margin-top: 16px;
    width: 650px;
}

.no-sub-wrapper {

    .pro-title {
        font-weight: 900;
    }

    sl-button {
        .pro-title {
            color: var(--consistent-main);
            margin-left: 7px;

        }
    }

    section {
        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: 25px;
        align-items: center;
        width: calc(100% - 30px);
        max-width: 500px;
        margin-top: 25px;
        animation: fade-in-cards 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @keyframes fade-in-cards {
            0% {
                transform: translateZ(-80px);
                opacity: 0;
            }
            100% {
                transform: translateZ(0);
                opacity: 1;
            }
        }

        .plan-cards {
            background-color: var(--tile-background);
            height: 300px;
            border-radius: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 7px solid transparent;
            justify-content: center;
            transition: border 0.2s ease;
            cursor: pointer;

            &.selected {
                border: 7px solid var(--shoebox-yellow);
            }

            h3 {
                font-size: 24pt;
                font-family: 'Reddit Mono', monospace;
                font-weight: 300;
            }
        }
    }

    .button-controls {
        display: grid;
        grid-template-columns: 30% 70%;
        column-gap: 12px;
        align-items: center;
        width: calc(100% - 18px);
        max-width: 513px;
        margin-top: 12px;

        #plan-selection-button {
            margin-left: 0;
        }
    }
}

@media only screen and (max-width: 450px) {
    .no-sub-wrapper {
        sl-button {
            width: 100%;
        }

        section {
            display: grid;
            grid-template-columns: 1fr;
            row-gap: 12px;
            column-gap: 0;
            width: 100%;
            align-items: center;
    
            .plan-cards {
                height: 275px;
            }
        }
    
        .button-controls {
            display: flex;
            flex-direction: column-reverse;
            width: 100%;
            align-items: center;

            #plan-selection-button {
                width: 100%;
                margin-bottom: 12px;
            }
        }
    }
}

.subscription-tile {
    background-color: var(--tile-background);
    padding: 15px 25px;
    border-radius: 25px;
    max-width: 600px;
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .subscription-image {
        padding: 15px;
        padding-top: 20px;
        margin-bottom: -5px;

        .subscription-image-skeleton, .subscription-image-skeleton::part(base), .subscription-image-skeleton::part(indicator) {
            height: 100px;
            width: 93px;
            margin-bottom: 5px;
            margin-top: 0px;
            border-radius: 10px;
            --sheen-color: var(--badge-primary);
            --color: var(--tile-background);
        }

        img {
            height: 100px;
            width: 93.52px;
        }
    }

    .subscription-details {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        width: 100%;

        sl-skeleton {
            border-radius: 5px;
        }

        .interval-skeleton {
            width: 90%;
            height: 25px;
        }

        .price-skeleton {
            width: 30%;
            height: 20px;
            margin: 15px 0 20px 0;
        }

        .support-text-skeleton {
            width: 85%;
            height: 15px;
            margin-bottom: 10px;
        }

        .title {
            font-size: 16pt;
            font-weight: 600;
            color: var(--text-color-main);
        }

        .price-line {
            margin-bottom: 10px;
            font-size: 12pt;
            font-weight: 400;
            color: var(--text-color-secondary);

            .canceled {
                font-weight: 500;
                color: var(--shoebox-error);
            }

            .dollars {
                font-family: 'Reddit Mono', monospace;
                color: var(--text-color-secondary);
            }

            .slash {
                padding: 0 5px;
                color: var(--text-color-secondary);
            }
        }

        .support-text {
            margin-bottom: 5px;
        }

        .support-text, .support-text-two {
            color: var(--text-color-secondary);
            font-size: 10pt;

            &.italic {
                font-style: italic;
            }
        }
    }
}

sl-button.portal-button::part(label) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: pre-wrap;
}

sl-button.portal-button {
   width: 100%;
   max-width: 650px;

   &.cancel {
    margin-top: 12px;
   }
}

@media only screen and (max-width: 525px) {
    .subscription-tile {
        flex-direction: column;

         .subscription-details {
            margin: 12px 0;
            text-align: center;
         }
    }
}