.contribution-table {
    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 10px;

        &.animated {
            animation: fade-in-table 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-table {
                0% {
                    transform: translateY(20px);
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        } 
    }

    .header-section, .expansion-section {
        &#animated {
            animation: fade-in-table-row 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-table-row {
                0% {
                    transform: translateY(20px);
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        } 
    }

    .edit-contribution-form {
        animation: fade-in-form 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        width: 100%;

        @keyframes fade-in-form {
            0% {
                transform: translateY(20px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }

        td {
            padding-bottom: 0;
            padding-top: 24px;
        }

        small {
            display: flex;
            align-items: center;
            margin-bottom: 7px;
            padding-top: 16px;

            svg {
                margin-right: 3px;
            }
            
            &.first {
                padding-top: 0;
            }
        }

        .full-width {
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .half-width {
            display: flex;
            width: 100%;

            .input-block {
                width: 100%;

                input {
                    width: calc(100% - 32px);
                }

                .amount, .dollar-sign {
                    font-family: 'Reddit Mono', monospace;
                }

                &.first-item {
                    margin-right: 16px;
                    font-weight: 400;

                    input {
                        width: calc(100% - 45.33px);
                    }
                }
            }
        }

        .split-width {
            width: calc(100% - 16px);
            display: grid;
            grid-template-columns: calc(100% - 133px) 133px;
            column-gap: 16px;

            .type-block {
                sl-radio-button::part(button) {
                    min-width: 60px;
                }
            }
            
            &.buttons {
                margin-top: 24px;
                width: calc(100% - 16px);
                display: grid;
                grid-template-columns: 30% 70%;
                column-gap: 16px;
            }
        }

        @media only screen and (max-width: 450px) {
            .split-width {
                &.buttons {
                    width: 100%;
                    display: grid;
                    grid-template-columns: 100%;
                    column-gap: 0;
                    row-gap: 16px;
                }
            }
        }

        hr {
            display: block;
            height: 1px;
            border: 0;
            border-bottom: 1px solid var(--table-border);
            margin: 0;
            padding-top: 24px;
        }
    }

    tr {
        width: 100%;
        font-size: 14pt;
        
        &.all {
            animation: fade-in-table-row 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            will-change: filter;

            @keyframes fade-in-table-row {
                0% {
                    will-change: filter;
                    filter: blur(5px);
                    transform: translateY(10px);
                    opacity: 0;
                }
                100% {
                    will-change: filter;
                    filter: blur(0px);
                    transform: translateY(0px);
                    opacity: 1;
                }
            }
        }

        td {
            padding: 10px 3px;

            &.expandable {
                cursor: pointer;
            }
        }

        td:first-child {
            font-weight: 600;
            width: 35%;
        }

        .static-cell-content {
            min-height: 27px;
            display: flex;
            align-items: center;

            .main-status-icon {
                font-size: 20pt;
            }

            .one-time-indicator {
                padding-top: 1px;
            }

            .recurring-indicator {
                margin-left: 3px;
            }

            .employer-indicator {
                margin-left: 4px;
            }

            &.number {
                font-family: "Reddit Mono", monospace;
            }

            &.date {
                justify-content: flex-end;
            }
        }

        .main-info-header-wrapper {
            display: flex;
            align-items: center;
            height: 0px;
            opacity: 0;
            will-change: filter;
            filter: blur(10px);
            overflow: hidden;
            transition: height 0.3s ease, filter 0.3s ease, opacity 0.3s ease;

            &.expanded {
                height: 22px;
                opacity: 1;
                will-change: filter;
                filter: blur(0px);
            }

            &.date {
                justify-content: flex-end;
                margin-right: 21.33px;
            }

            small {
                margin-bottom: 0;
                display: flex;

                svg {
                    margin-right: 3px;
                    font-size: 14pt;
                }
            }
        }

        .amount {
            width: 30%;
        }

        .date {

            .more-info-chevron-wrapper {
                display: flex;
                align-items: center;
                overflow: hidden;
                width: 0px;
                transition: width 0.3s ease;

                &.expanded {
                    width: 21.33px;
                }
            }

            .toggle-icon {
                display: flex;
                align-items: center;
                width: fit-content;
                min-width: 0;

                svg {
                    font-size: 16pt;
                    color: var(--shoebox-yellow);
                    transform: rotate(0deg);
                    transition: transform 0.3s ease;

                    &.open {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        &.upcoming {

            td, span, div {
                color: var(--table-upcoming);
            }
        }

        &.skipped {
            td, span, div {
                color: var(--table-upcoming);
            }

            .header {
                color: var(--shoebox-error);
                text-decoration: none !important;
            }

            .number, .date {
                &.value {
                    text-decoration: line-through; 
                }
            }
        }
    }

    @media only screen and (max-width: 385px) {
        tr {
            font-size: 12pt;

            .main-info-header-wrapper {
    
                &.date {
                    margin-right: 14.33px;
                }
            }

            .date {

                .more-info-chevron-wrapper {
    
                    &.expanded {
                        margin-right: -7px;
                    }
                }
            }
        }
    }

    .expansion-section {
        height: fit-content;
        overflow: hidden;
        border-bottom: 1px solid var(--table-border);

        &.upcoming {
            color: var(--table-upcoming);
        }

        td {
            padding: 0;
        }

        .additional-information-wrapper  {
            display: grid;
            width: calc(100% - 6px);
            grid-template-rows: 0fr;
            overflow: hidden;
            transition: grid-template-rows 0.3s ease, padding 0.3s ease;
            padding: 0 3px;

            &.expanded {
                grid-template-rows: 1fr;
                padding-bottom: 15px;
            }

            .expanded-content {
                min-height: 0;
                display: flex;
                flex-direction: column;
                width: 100%;

                small {
                    margin-bottom: 0;
                    height: 22px;
                    display: flex;
                    align-items: center;
                    opacity: 0;
                    will-change: filter;
                    filter: blur(10px);
                    transition: opacity 0.3s ease, filter 0.3s ease, margin 0.3s ease;

                    &.visible {
                        opacity: 1;
                        will-change: filter;
                        filter: blur(0px);
                        margin-bottom: 5px;
                    }

                    svg {
                        margin-right: 3px;
                        font-size: 14pt;
                    }
                }

                .title-section {
                    display: grid;
                    grid-template-columns: 1fr auto;
                    width: 100%;
                    margin-top: 10px;

                    .last-edit-block {
                        justify-self: flex-end;
                        margin-right: 21.33px;
                        padding-left: 15px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;

                        small > svg {
                            font-size: 14pt;
                        }

                        .last-edit {
                            font-weight: 400;
                            font-family: 'Reddit Mono', monospace;
                        }
                    }

                    svg {
                        font-size: 12pt;
                    }
                }

                .metadata-n-control-section {
                    margin-top: 16px;
                    display: grid;
                    grid-template-columns: 1fr auto;
                    width: 100%;

                    .type-value {
                        font-weight: 400;
                        font-size: 12pt;
                        background-color: var(--tile-background);
                        height: 35px;
                        color: var(--text-color-main);
                        padding: 0 10px 2px 10px;
                        margin-top: 5px;
                        border-radius: 10px;
                        width: fit-content;
                    }

                    .actions-block {
                        justify-self: flex-end;
                        margin-right: 21.33px;

                        small {
                            justify-content: flex-end;
                        }

                        .action-btns sl-button:first-of-type {
                            margin-right: 8px;
                        }
    
                        .action-btns {
                            display: flex;

                            .delete-cancel {
                                width: 60px;
                                transition: all 0.3s ease;

                                &.expanded {
                                    width: 40px;
                                    overflow: hidden;

                                    svg {
                                        font-size: 18pt;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                    }

                                    sl-button.delete-cancel::part(label) {
                                        padding: 0 7px;
                                    }
                                }

                            }

                            .delete-main {
                                width: 80px;
                                transition: all 0.3s ease;

                                &.expanded {
                                    width: 145px;
                                    overflow: hidden;
                                }
                            }
                        }
                    }
                }

                &.upcoming {
                    .title-section {
                        display: flex;
                        justify-content: space-between;
    
                        span {
                            color: var(--table-upcoming);
                        }

                        .type {
                            font-weight: 400;
                            font-size: 12pt;
                            background-color: var(--tile-background);
                            height: 35px;
                            color: var(--table-upcoming);
                            padding: 0 10px 2px 10px;
                            margin-top: 5px;
                            border-radius: 10px;
                        }

                        .action-btns sl-button:first-of-type {
                            margin-right: 8px;
                        }
    
                        .action-btns {
                            margin-right: 21.33px;
                        }

                        .actions-column {
                            display: flex;
                            align-items: flex-end;
                            flex-direction: column;
                        }
    
                        .actions-label {
                            margin-right: 21.33px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 385px) {
        .expansion-section {
            .additional-information-wrapper  {
                .expanded-content {
                    .metadata-n-control-section {
                        .actions-block {
                            margin-right: 14.33px;
                        }
                    }
                }
            }
        }
    }
}

.table-legend {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &.animated {
        animation: fade-in-legend 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation-delay: 0.25s;
    
        @keyframes fade-in-legend {
            0% {
                will-change: filter;
                filter: blur(5px);
                transform: translateY(7px);
                opacity: 0;
            }
            100% {
                will-change: filter;
                filter: blur(0px);
                transform: translateY(0px);
                opacity: 1;
            }
        }
    }
    

    small {
        display: flex;
        align-items: center;
        margin-right: 12px;
        margin-bottom: 0;
        color: var(--text-color-tertiary);
        font-size: 10pt;

        svg {
            margin-right: 4px;
            font-size: 14pt;
            color: var(--text-color-secondary);
        }

        &.one-time {
            svg {
                margin-right: 1px;
            }
        }
    }
}