.schedule-panel {

    .new-contribution-buttons {
        sl-button {
            width: 100%;
        }

        .create-schedule-btn {
            margin-bottom: 12px;
        }
    }

    .schedule-display-box {
        background-color: var(--tile-background);
        padding: 15px;
        border-radius: 15px;
        margin-bottom: 12px;

        h2 {
            font-weight: 600;
            font-size: 15pt;
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            #schedule-header-loading-indicator {
                margin-right: 10px;
            }

            .recurring-icon {

                svg {
                    padding: 7px;
                    color: var(--text-color-tertiary);
                    font-size: 24pt;
                    padding-left: 0;
                }

                &.active {
                    svg {
                        color: var(--success-toast-icon);
                    }
                }
            }
        }

        h3 {
            font-size: 12pt;
            font-weight: 600;
            display: flex;
            align-items: center;

            svg {
                font-size: 16pt;
                margin-right: 3px;
            }

            #schedule-loading-indicator {
                padding-left: 10px;
            }

            sl-badge {
                margin-left: 7px;
            }
        }

        .schedule-section:not(:last-child) {
            margin-bottom: 16px;
        }

        .schedule-information {
            margin-top: 5px;
            div {
                color: var(--text-color-secondary);
                margin-bottom: 3px;
                
                strong {
                    color: var(--text-color-main);
                }
            }
        }
    }

    

    .editor-panel {
        background-color: var(--tile-background);
        padding: 15px;
        border-radius: 20px;
        margin-bottom: 12px;
        animation: fade-in-bottom 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @keyframes fade-in-bottom {
            0% {
                transform: translateY(20px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
          }

        .schedule-panel-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;

            h2 {
                font-size: 16pt;
                font-weight: 500;
            }

            .cancel-edit-btn {
                display: flex;
                align-items: center;
                
                svg {
                    color: var(--button-secondary);
                    font-size: 27pt;
                    margin-left: 6px;
                    transform: rotate(45deg);
                    cursor: pointer;
                    transition: color 0.3s ease;

                    body.hasHover &:hover {
                        color: var(--button-secondary-hover);
                    }
                }
            }
        }

        .save-error-block {
            display: block;
            margin-top: -15px;
            margin-bottom: 20px;
            font-size: 10pt;
            color: var(--shoebox-error);
            animation: fade-in-save-error 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-save-error {
                0% {
                    transform: translateY(12px);
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    opacity: 1;
                }
              }
              
        }

        .contribution-source-toggle::part(button-group) {
            background-color: var(--background-color);
            width: 100%;
            margin-bottom: 30px;
        }

        .contribution-source-option {
            width: 100%;

            &::part(button) {
                width: 100%;
            }
        }

        .individual-header, .employer-header {
            margin-bottom: 25px;

            h3 {
                font-size: 16pt;
                font-weight: 600;
                margin-bottom: 12px;
                display: flex;
                align-items: center;

                svg {
                    margin-right: 3px;
                    font-size: 18pt;
                }
            }

            .employer-h-three {
                svg {
                    font-size: 19pt;
                }
            }

            sl-radio-button::part(button) {
                min-width: 60px;
            }

            .has-individual-schedule-toggle::part(button-group), .has-employer-schedule-toggle::part(button-group) {
                background-color: var(--background-color);
            }
        }

        .individual-form-wrapper, .employer-form-wrapper {
            display: grid;
            grid-template-rows: 0fr;
            overflow: hidden;
            margin-bottom: 0;
            transition: grid-template-rows 0.3s ease, margin 0.3s ease;


            &.expanded {
                grid-template-rows: 1fr;
                margin-bottom: 25px;
            }
        }

        .schedule-controls {
            min-height: 0;
            padding: 2px;

            .form-error-wrapper {
                display: grid;
                grid-template-rows: 0fr;
                overflow: hidden;
                will-change: filter;
                filter: blur(10px);
                padding: 0;
                opacity: 0;
                transition: all 0.3s ease;
        
                &.shown {
                    grid-template-rows: 1fr;
                    will-change: filter;
                    filter: blur(0px);
                    padding-bottom: 10px;
                    margin-top: -5px;
                    opacity: 1;
                }
            }
        
            .amount-error-text {
                font-family: 'Reddit Mono', monospace;
                color: var(--shoebox-error);
                display: block;
            }

            .contribution-title-section {
                margin-bottom: 30px;
                display: block;

                input {
                    background-color: var(--background-color);
                    width: calc(100% - 32px);

                }
            }

            .schedule-amount-selectors {
                display: grid;
                grid-template-columns: calc(50% - 6px) calc(50% - 6px);
                column-gap: 12px;
                margin-bottom: 30px;

                .blocks-currency-input {
                    input {
                        width: calc(100% - 45px);
                        padding: 0 16px 0 32px;
                    }
                    span {
                        height: 72%;
                        margin-top: 4px;
                        font-size: 16pt;
                        font-family: 'Reddit Mono', monospace;
                    }
                }


                input, sl-select::part(form-control-input) {
                    background-color: var(--background-color);
                }

                input {
                    text-align: center;
                    font-family: 'Reddit Mono', monospace;
                    font-size: 16pt;
                }

                sl-select::part(display-input) {
                    text-align: center;
                    font-size: 16pt
                }

                sl-select::part(combobox) {
                    background-color: var(--background-color);
                    border-radius: 15px;
                }

                sl-select::part(expand-icon) {
                    display: none;
                }
            }

            .schedule-date-pickers {
                display: grid;
                grid-template-columns: 85px 1fr;
                align-items: center;
                margin-bottom: 12px;

                input {
                    background-color: var(--background-color);
                }

                input[type="date"] {
                    -webkit-appearance: none;
                }
            }

            .end-date-toggle-wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                sl-radio-button::part(button) {
                    min-width: 60px;
                }

                .end-date-toggle::part(button-group) {
                    background-color: var(--background-color);
                    margin-left: 12px;
                }
            }

            .one-time-dual-field-section {
                display: flex;
                margin-bottom: 30px;

                .amount {
                    display: block;
                    width: 100%;
                    
                    .blocks-currency-input {
                        display: flex;
                        align-items: center;

                        input {
                            width: calc(100% - 45px);
                            padding: 0 16px 0 32px;
                            text-align: center;
                            font-family: "Reddit Mono", monospace;
                            font-size: 16pt;
                            background-color: var(--background-color);
                        }
                        .dollar-sign {
                            font-size: 16pt;
                            font-family: 'Reddit Mono', monospace;
                        }
                    }
                }

                .employer-toggle {
                    margin-left: 12px;

                    sl-radio-group.one-time-employer-toggle::part(button-group) {
                        background-color: var(--background-color);
                    }

                    sl-radio-button::part(button) {
                        min-width: 60px;
                    }
                }

            }

            .contribution-date-input-section {
                margin-bottom: 12px;
                display: block;

                input {
                    background-color: var(--background-color);
                    width: calc(100% - 30px);
                }

                input[type="date"] {
                    -webkit-appearance: none;
                }
            }

            .cont-form-button-group-label {
                font-size: 12pt;
                font-weight: 500;
            }
        }

        .schedule-form-footer {
            display: flex;
            align-items: center;

            div {
                width: 290px;

                small {
                    margin: 0;
                }

                sl-switch {
                    margin-right: -8px;
                }
            }

            
        }

        sl-button.save-schedule-button {
            width: 100%;
        }

        sl-button.save-one-time-button {
            width: 100%;
            margin-top: 20px;
        }
    }

    .otc-btn {
        width: 100%;
    }

    .active-schedule-controls {
        display: grid;
        grid-template-columns: 1fr 2fr;
        column-gap: 12px;
        margin-bottom: 12px;
        width: 100%;
        
        &.animated {
            animation: fade-in-sched-controls 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-sched-controls {
                0% {
                    will-change: filter;
                    filter: blur(5px);
                    opacity: 0;
                }
                100% {
                    will-change: filter;
                    filter: blur(0px);
                    opacity: 1;
                }
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .active-schedule-controls {
            grid-template-columns: 1fr;
            column-gap: unset;
            row-gap: 12px;
        }
    }

    @media only screen and (max-width: 850px) {
        .active-schedule-controls {
            display: grid;
            grid-template-columns: 1fr 2fr;
            column-gap: 12px;
            width: 100%;
        }
    }

    @media only screen and (max-width: 500px) {
        .active-schedule-controls {
            grid-template-columns: 1fr;
            column-gap: unset;
            row-gap: 12px;
        }
    }
}