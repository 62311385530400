#create-account-container {
    position: relative;

    .price-card {
        padding: 50px;
        background-color: var(--tile-background);
        border-radius: 25px;
        width: fit-content;
        animation: fade-in-price-card 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @keyframes fade-in-price-card {
            0% {
                -webkit-transform: translateY(10px);
                transform: translateY(10px);
                opacity: 0;
                will-change: filter;
                filter: blur(5px);
            }
            100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
                opacity: 1;
                will-change: filter;
                filter: blur(0);
            }
        }
    }

    .price-dots {
        display: flex;
        align-items: center;

        .pricing-dot {
            background-color: var(--background-color);
            border-radius: 50%;
            padding: 0px;
            height: 250px;
            width: 250px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            justify-content: center;
            border: 6px solid var(--background-color);
            transition: border 0.3s ease;
            cursor: pointer;

            .savings-chip {
                background-color: var(--savings-chip);
                border-radius: 50px;
                padding: 4px 12px;
                font-size: 10pt;
                margin-bottom: 3px;
                margin-top: 5px;
            }

            .tier-title {
                font-weight: 600;
                margin-bottom: 8px;
                margin-top: 5px;
            }

            .trial-chip {
                background-color: var(--shoebox-yellow);
                border-radius: 50px;
                padding: 4px 16px;
                margin-bottom: 5px;
                font-style: italic;
            }

            .dot-price {
                font-family: 'Reddit Mono', monospace;
                font-weight: 700;
                font-size: 48pt;
                margin: -5px 0;
            }

            .tier-term {
                color: var(--text-color-tertiary);
            }

            &.selected {
                border: 6px solid var(--shoebox-yellow);
            }
        }

        .or-divider {
            margin: 0 25px;
            font-size: 16pt;
            font-weight: 700;
        }
    }

    .cta-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 25px;
        width: 100%;

        span {
            color: var(--text-color-secondary);
            margin-bottom: 10px;
        }

        sl-button {
            width: 100%;
        }
    }

    .create-account-header {
        position: fixed;
        top: 70px;
        display: flex;
        justify-content: space-between;
        width: calc(100% - 50px);
        max-width: 1250px;
        background-color: var(--background-color);
        padding-top: 20px;
        padding-bottom: 20px;
        z-index: 3;

        h1 {
            margin: 0;
        }

        .left-header {
            @keyframes fade-in-subheader-a {
                0% {
                    -webkit-transform: translateX(20px);
                    transform: translateX(20px);
                    opacity: 0;
                }
                100% {
                    -webkit-transform: translateX(0);
                    transform: translateX(0);
                    opacity: 1;
                }
            }
    
            .create-account-subheader-a  {
                animation: fade-in-subheader-a 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                font-size: 18pt;
                color: var(--text-color-secondary);
            }
    
        }

        .selected-plan-card {
            background-color: var(--tile-background);
            padding: 5px 15px;
            display: flex;
            border-radius: 20px;
            align-items: center;
            width: fit-content;
            animation: fade-in-card 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-card {
                0% {
                  -webkit-transform: translateX(20px);
                          transform: translateX(20px);
                  opacity: 0;
                }
                100% {
                  -webkit-transform: translateX(0);
                          transform: translateX(0);
                  opacity: 1;
                }
            }

            // Subheader animation is used here bc I cannot figure out why translate isn't animating outside this instance. Not worth the time to debug rn.
            &.subheader {
                background-color: transparent;
                padding: 0;
                margin: 0;
                font-size: 18pt;
                color: var(--text-color-secondary);
                animation: fade-in-card-b 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

                @keyframes fade-in-card-b {
                    0% {
                    -webkit-transform: translateX(10px);
                            transform: translateX(10px);
                    opacity: 0;
                    }
                    100% {
                    -webkit-transform: translateX(0);
                            transform: translateX(0);
                    opacity: 1;
                    }
                }
            }

            img {
                margin-top: 7px;
                margin-bottom: 3px;
                height: 40px;
                aspect-ratio: 1 / 1;
                object-fit: contain;
            }

            .selected-details {
                margin-left: 12px;
                margin-right: 5px;
                margin-bottom: 5px;
                margin-top: 5px;

                h2 {
                    font-size: 16pt;
                    font-weight: 600;
                }
                div, span {
                    color: var(--text-color-secondary);
                }
            }
        }

        .swap-plan-button {
            display: block;
            font-size: 12pt;
            font-weight: 500;
            color: var(--shoebox-yellow);
            margin-left: 15px;
            padding-top: 5px;
            cursor: pointer;
            animation: fade-in-swap 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation-delay: 0.2s;

            @keyframes fade-in-swap {
                0% {
                  opacity: 0;
                  will-change: filter;
                  filter: blur(5px);
                }
                100% {
                  opacity: 1;
                  will-change: filter;
                  filter: blur(0);
                }
              }
              
        }
    }

    @media only screen and (max-width: 740px) {
        .create-account-header {
            flex-direction: column;
            width: calc(100% - 30px);

            h1 {
                font-size: 24pt;
            }
    
            .create-account-subheader {
                font-size: 14pt;
                color: var(--text-color-secondary);
            }

            .plan-card-wrapper {
                display: flex;
                align-items: center;

                .swap-plan-button {

                    svg {
                        font-size: 24pt;
                        margin-top: 12px;
                        padding: 5px 0; 
                    }
                }
            }

            .selected-plan-card {
                margin-top: 12px;
            }
        }
    }

    .page-content {
        display: block;

        #pricing-tiers {
            padding: 0;

            .price-block {
                margin: 0;
            }

        }

        .plan-card-wrapper {
            margin-bottom: 25px;
        }

        

        // .pricing-tiers {
        //     margin-top: 170px;
        // }

        // .account-details {
        //     margin-top: 185px;
        // }
    }

}