.sign-in {
    width: 100%;
    max-width: 500px;
    position: relative;

    sl-input {
        margin-bottom: 25px;

        a {
            color: var(--shoebox-yellow);
            font-weight: 400;
            font-size: 12pt;
        }

        .password-label {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }

    sl-input::part(form-control-label) {
        width: 100%;
    }

    sl-button {
        width: 100%;
    }

    .password-toggle {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            color: var(--text-color-secondary);
        }
    }

    .alert {
        font-family: 'Inter', sans-serif;
        background-color: var(--shoebox-error);
        color: var(--background-color);
        padding: 5px 25px;
        border-radius: 50px;
        width: fit-content;
        margin: auto;
        margin-bottom: 25px;
        display: none;
        text-align: center;
        
        height: 0;

        &.error {
            height: fit-content;
            display: block;
            animation: alert-animation 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes alert-animation {
                0% {
                  -webkit-transform: translateY(10px);
                          transform: translateY(10px);
                  opacity: 0;
                }
                100% {
                  -webkit-transform: translateY(0);
                          transform: translateY(0);
                  opacity: 1;
                }
              }
              
        }
    }

    .desktop-has-account {
        margin: 15px auto;
        text-align: center;
        font-weight: 500;

        span {
            color: var(--text-color-secondary);
        }

        a {
            color: var(--shoebox-yellow);
        }
    }

    .sign-in-controls {
        position: fixed;
        max-width: 650px;
        bottom: 0;
        width: calc(100% - 30px);
    
        .mobile-has-account {
            margin: 15px auto;
            margin-bottom: 25px;
            text-align: center;
            font-weight: 500;
    
            span {
                color: var(--text-color-secondary);
            }

            a {
                color: var(--shoebox-yellow);
            }
        }
    }
}

@media only screen and (max-width: 740px) {
    .sign-in {
        max-width: unset;

        sl-input {
            margin: auto;
            max-width: 500px;
            margin-bottom: 25px;
        }

        sl-button {
            max-width: 500px;
            margin: auto;
        }

        .sign-in-controls {
            display: flex;
            align-items: center;
            flex-direction: column;
        }
    }
}