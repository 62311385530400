.investments-panel-loader {
    sl-skeleton.investing-card-loader {
        width: 100%;
        height: 125px;
        border-radius: 15px;
    }

    sl-skeleton.investing-button-loader {
        width: 100%;
        height: 50px;
        margin-top: 12px;
        border-radius: 15px;
    }
}

.investments-panel {

    .set-investments-btn {
        width: 100%;
    }

    .index-selector {
        span {
            display: block;
            margin-bottom: 12px;
        }
        sl-button {
            width: 100%;
        }
        .index-tiles {
            animation: fade-in-tiles 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-tiles {
                0% {
                    transform: translateY(20px);
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    opacity: 1;
                }
            }

            .button-row {
                sl-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                h2 {
                    font-weight: 900;
                }
                small {
                    margin: 0;
                    line-height: 14pt;
                    height: fit-content;
                    white-space: wrap;
                }
                margin-top: 10px;
                display: grid;
                grid-template-columns: 50% 50%;
                column-gap: 12px;
                justify-content: space-between;
                width: calc(100% - 12px);
                margin-bottom: 12px;
            }
            .index-tile {
                height: 85px;
                background-color: var(--sl-color-info-500);
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                border: 5px solid var(--sl-color-info-500);
                // border-style: inset;
                cursor: pointer;
                padding: 0 10px;
                border-radius: 15px;
                transition: border 0.3s ease;

                &.selected {
                    border: 5px solid var(--shoebox-yellow);
                }
            }
        }

        @media only screen and (max-width: 475px) {
            .index-tiles {
                .button-row {
                    grid-template-columns: 1fr;
                    row-gap: 12px;
                    column-gap: 0;
                    width: 100%;
                }
            }
        }
    }

    .unset-investment-buttons {
        width: 100%;
        row-gap: 12px;

        &.animated {
            animation: fade-in-unset 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-unset {
                0% {
                    transform: translateY(7px);
                    will-change: filter;
                    filter: blur(5px);
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    will-change: filter;
                    filter: blur(0px);
                    opacity: 1;
                }
            }    
        }
        
        .track-index-btn {
            width: 100%;
            margin-bottom: 12px;
        }
        
        .secondary-selections {
            display: grid;
            width: calc(100% - 12px);
            grid-template-columns: 50% 50%;
            column-gap: 12px;
        }

        @media only screen and (max-width: 475px) {
            .secondary-selections {
                display: grid;
                width: 100%;
                grid-template-columns: 1fr;
                row-gap: 12px;
            }
        }
    }

    .selected-index-card {
        background-color: var(--tile-background);
        padding: 15px;
        border-radius: 15px;
        
        &.animated {
            animation: fade-in-selected 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-selected {
                0% {
                    transform: translateY(20px);
                    opacity: 0;
                }
                100% {
                    transform: translateY(0);
                    opacity: 1;
                }
            }
        }

        .selected-index-header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            .index-header-starter-badge {
                background-color: var(--button-secondary);
                padding: 5px 15px;
                font-weight: 500;
                border-radius: 25px;
            }
        }

        .selected-index-body-text {
            font-size: 14pt;
            margin-top: 10px;
            display: block;
        }

        h2 {
            font-weight: 900;
        }
    }

    .index-selection-buttons {
        display: grid;
        width: calc(100% - 12px);
        grid-template-columns: 60% 40%;
        column-gap: 12px;

        &.animated {
            animation: fade-in-controls 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-controls {
                0% {
                    will-change: filter;
                    filter: blur(5px);
                    opacity: 0;
                }
                100% {
                    will-change: filter;
                    filter: blur(0px);
                    opacity: 1;
                }
            }
        }   
    }

    .edit-index-buttons {
        display: grid;
        grid-template-columns: 50% 50%;
        width: calc(100% - 12px);
        column-gap: 12px;
        margin: 12px 0;

        &.pro-edit-option {
            display: grid;
            grid-template-columns: 1fr;
            width: 100%;
        }

        #pro-upgrade-marker {
            font-weight: 900;
        }
    }

    .edit-non-tracking {
        margin: 12px 0;
        
        sl-button {
            width: 100%;
        }
    }

    @media only screen and (max-width: 500px) {
        .edit-index-buttons {
            grid-template-columns: 1fr;
            row-gap: 12px;
            column-gap: 0;
            width: 100%;
        }
    }

    // @media only screen and (max-width: 1075px) {
    //     .index-selection-buttons {
    //         width: 100%;
    //         grid-template-columns: 1fr;
    //         column-gap: unset;
    //         row-gap: 12px;
    //     }
    // }

    // @media only screen and (max-width: 740px) {
    //     .index-selection-buttons {
    //         width: calc(100% - 12px);
    //         grid-template-columns: 70% 30%;
    //         column-gap: 12px;
    //         row-gap: unset;
    //     }
    // }

    // @media only screen and (max-width: 500px) {
    //     .index-selection-buttons {
    //         width: 100%;
    //         grid-template-columns: 1fr;
    //         column-gap: unset;
    //         row-gap: 12px;
    //     }
    // }
}

.cash-investment-form {
    .form-error-wrapper {
        display: grid;
        grid-template-rows: 0fr;
        overflow: hidden;
        will-change: filter;
        filter: blur(10px);
        padding: 0;
        opacity: 0;
        transition: all 0.3s ease;

        &.shown {
            grid-template-rows: 1fr;
            will-change: filter;
            filter: blur(0px);
            padding-bottom: 10px;
            opacity: 1;
        }
    }

    .cash-form-error {
        font-family: 'Reddit Mono', monospace;
        color: var(--shoebox-error);
        display: block;
    }

    .cash-form-fields {
        display: flex;

        .percentage-input-field {
            position: relative;

            span {
                position: absolute;
                top: 14px;
                right: 15px;
                margin: 0;
                font-size: 14pt;
                color: var(--text-color-secondary);
            }
        }
    }

    span {
        display: flex;
        flex-direction: column;
        animation: fade-in-form 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @keyframes fade-in-form {
            0% {
                transform: translateY(20px);
                opacity: 0;
            }
            100% {
                transform: translateY(0);
                opacity: 1;
            }
        }

        label {
            font-weight: 500;
            font-size: 14pt;
            margin-bottom: 10px;
        }

        &:first-of-type {
            margin-right: 32px;
        }
    }

    sl-radio-button::part(button) {
        min-width: 60px;
    }

    .cash-controls {
        margin-top: 16px;
        display: grid;
        grid-template-columns: 50% 50%;
        width: calc(100% - 12px);
        column-gap: 12px;
        animation: fade-in-controls 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

        @keyframes fade-in-controls {
            0% {
                will-change: filter;
                filter: blur(5px);
                opacity: 0;
            }
            100% {
                will-change: filter;
                filter: blur(0px);
                opacity: 1;
            }
        }
    }
}