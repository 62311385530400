#pricing {
    margin: 100px 0;

    .pricing-block {
        background-color: var(--tile-background);
        border-radius: 25px;
        padding: 50px 0;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        .product-features {
            padding: 0 25px;

            .feature-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                
                &:not(:last-child) {
                    margin-bottom: 26px;
                }

                .feature-graphic {
                    font-size: 36pt;
                    font-weight: 600;
                    margin-bottom: 5px;
                    display: flex;
                    align-items: center;

                    &.unlimited {
                        font-size: 34pt;
                        margin-bottom: -5px;
                    }

                    &.devices {
                        margin: 0 -5px;
                        margin-top: -10px;
                        display: block;
                    }

                    svg {
                        margin: 0 3px;

                        &.phone {
                            font-size: 26pt;
                        }

                        &.tablet {
                            font-size: 34pt;
                            margin-left: -7px;
                        }

                        &.laptop {
                            font-size: 54pt;
                            margin-bottom: -10px;
                            margin-left: -2px;
                        }

                        &.star-banner {
                            font-size: 32pt;
                        }
                    }
                }

                .feature-description {
                    font-size: 16pt;
                }
            }
        }

        .selection-window {
            background-color: var(--background-color);
            border-radius: 25px;
            width: 400px;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 25px;

            .selection-text {
                text-align: center;
                margin: 25px 0;
                .support-text {
                    color: var(--text-color-secondary);
                }

                .selected-tier {
                    font-size: 18pt;
                    font-weight: 500;
                }
            }

            .pricing-display-month, .pricing-display-year {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin-bottom: 55px;

                .dollar-figure {
                    font-family: 'Reddit Mono', monospace;
                    font-weight: 700;
                    font-size: 60pt;
                }
            }

            .pricing-display-month {
                animation: fade-in-price-display-month 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

                @keyframes fade-in-price-display-month {
                    0% {
                        -webkit-transform: translateY(10px);
                        transform: translateY(10px);
                        opacity: 0;
                        will-change: filter;
                        filter: blur(5px);
                    }
                    100% {
                        -webkit-transform: translateY(0);
                        transform: translateY(0);
                        opacity: 1;
                        will-change: filter;
                        filter: blur(0);
                    }
                }
            }

            .pricing-display-year {
                animation: fade-in-price-display-year 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

                @keyframes fade-in-price-display-year {
                    0% {
                        -webkit-transform: translateY(10px);
                        transform: translateY(10px);
                        opacity: 0;
                        will-change: filter;
                        filter: blur(5px);
                    }
                    100% {
                        -webkit-transform: translateY(0);
                        transform: translateY(0);
                        opacity: 1;
                        will-change: filter;
                        filter: blur(0);
                    }
                }
            }

            .cta-block {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                span {
                    color: var(--text-color-secondary);
                    margin-bottom: 10px;
                    font-size: 12pt;
                }

                sl-button {
                    width: 100%;
                }
            }
        }
    }

    @media only screen and (max-width: 950px) {
        .pricing-block {
            .selection-window {
                width: 350px;
            }
        }
    }

    @media only screen and (max-width: 900px) {
        .pricing-block {
            .product-features {
                padding: 0 15px;
            }
            .selection-window {
                width: 300px;
            }
        }
    }

    @media only screen and (max-width: 800px) {
        .pricing-block {
            padding: 0;
            padding-bottom: 25px;
            flex-direction: column-reverse;
    
            .product-features {
                margin: 55px 0;
            }

            .selection-window {
                width: 400px;
            }
        }
    }

    @media only screen and (max-width: 625px) {
        .pricing-block {
    
            .price-dots {

                .pricing-dot {
                    height: 200px;
                    width: 200px;
    
                    .trial-chip {
                        font-size: 10pt;
                    }
    
                    .dot-price {
                        font-size: 36pt;
                    }
    
                    .tier-term {
                        font-size: 10pt;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 550px) {
        .pricing-block {
            
            .product-features {
                margin: 35px 0;
                padding: 0;
            }

            .features-and-selection {
                width: 100%;
            }

            .selection-window {
                width: calc(100% - 100px);
                margin: 0 25px;
                border-radius: 20px;
            }

            .product-features {
                h3 {
                    font-size: 24pt;
                    font-weight: 600;
                }
    
                .feature-block {
                    padding: 0 12px;
    
                    .feature-description {
                        font-size: 14pt;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 450px) {
        .pricing-block {
            padding-bottom: 15px;

            .selection-window {
                width: calc(100% - 60px);
                margin: 0 15px;
                padding: 15px;
            }
        }
    }
}