// Handles content formatting for both privacy policy & terms of use

.terms-of-use-page, .privacy-policy-page {

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        background-color: var(--background-color);
        padding-top: 10px;
        top: 70px;
        width: calc(100% - 50px);
        max-width: 1250px;

        h1 {
            margin: 0;
        }

        a {
            display: flex;
            align-items: center;
            color: var(--text-color-main);
            transition: color 0.3s ease;
            cursor: pointer;

            svg {
                margin-left: 6px;
                font-size: 24pt;
            }

            body.hasHover &:hover {
                color: var(--shoebox-yellow);
            }
        }
    }

    .policy-loader, .terms-loader {
        width: 100%;
        max-width: 700px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 35vh;
    }

    .policy-error-text, .terms-error-text {
        margin-top: 150px;
        font-size: 14pt;
        color: var(--text-color-secondary);
    }

    .terms-of-use-body, .privacy-policy-body {
        max-width: 700px;
        margin-top: 140px;


        &.has-footer {
            margin-bottom: 250px;
        }

        .last-updated-date {
            background-color: var(--tile-background);
            padding: 5px 10px;
            width: fit-content;
            border-radius: 10px;
            color: var(--text-color-secondary);

            strong {
                font-weight: 600;
            }
        }

        h2 {
            font-weight: 300;
            margin-bottom: 12px;
            margin-top: 25px;
        }

        a {
            color: var(--shoebox-yellow);
        }

        p {
            margin-bottom: 12px;

            strong {
                font-weight: 600;
            }
        }

        li {
            margin-bottom: 6px;
        }
    }
}

@media screen and (max-width: 740px) {
    .terms-of-use-page, .privacy-policy-page {

        .header {
            width: calc(100% - 30px);
        }
    }
}

@media screen and (max-width: 600px) {
    .terms-of-use-page, .privacy-policy-page {

        .header {
            flex-direction: column;
            align-items: flex-start;

            a {
                margin: 6px 0;

                svg {
                    font-size: 18pt;
                }
            }
        }

        .terms-of-use-body, .privacy-policy-body {
            margin-top: 180px;

            &.has-footer {
                margin-bottom: 375px;
            }
        }
    }
}