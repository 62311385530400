#account-value-loading-tile {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .account-value-loader {
        width: 100%;
        height: calc(100% - 25px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

#account-value-tile {
    position: relative;
    padding: 0;
    width: 100%;

    .tile-header {
        position: absolute;
        padding: 15px 25px 0 25px;
        z-index: 10;

        .datapoint-detail {
            background-color: var(--tile-button-background-translucent);
            will-change: backdrop-filter;
            backdrop-filter: blur(3px);
            width: fit-content;
            padding: 4px 12px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            
            span {
                font-family: 'Reddit Mono', monospace;
            }

            .divider {
                color: var(--tile-button-background);
                font-size: 12pt;
                padding-left: 6px;
            }

            .percent-change {
                font-weight: 600;
                display: flex;
                align-items: center;

                svg {
                    font-size: 16pt;
                    transition: transform 0.15s ease;
                }

                &.positive {
                    color: var(--success-toast-icon);

                    svg {
                        transform: rotate(45deg);
                    }
                }

                &.negative {
                    color: var(--shoebox-error);

                    svg {
                        transform: rotate(135deg);
                    }
                }

                &.neutral {
                    color: var(--success-toast-icon);

                    svg {
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .account-value {
            font-family: 'Reddit Mono', monospace;
            display: flex;
            align-items: baseline;

            .ticker-view {
                margin: 0;
                
                div, span {
                    font-family: 'Reddit Mono', monospace;
                }
            }

            &.not-set {
                font-family: 'Inter', sans-serif;
                font-weight: 500;
                color: var(--text-color-tertiary);
            }
        }
    }

    .empty-data {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        color: var(--text-color-secondary);
        height: 100%;
        text-align: center;
        padding: 0 15px;

        svg {
            font-size: 24pt;
            color: var(--shoebox-yellow);
            margin-bottom: 10px;
        }

        .line-with-link {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            color: var(--text-color-secondary);

            a {
                color: var(--text-color-secondary);
                text-decoration: underline;
                font-weight: 500;
                margin: 0 5px;
            }
        }
    }

    canvas {
        position: absolute;
        padding: 0 10px;
        margin-top: -25px;
        bottom: 0;
        z-index: 9;
    }

    .interval-selector-wrapper {
        padding: 20px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 10;
        display: grid;
        grid-template-rows: 0fr;
        overflow: hidden;
        transition: grid-template-rows 0.3s ease;
    }

    .interval-option-drawer {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--tile-chart-background);
        padding: 3px;
        height: 30px;
        border-radius: 15px;
        z-index: 10;
        cursor: pointer;
        overflow: hidden;
        transition: height 0.3s ease, padding 0.3s ease;

        &.expanded {
            height: 174px;
            padding: 5px 4px;
        }

        button {
            font-size: 12pt;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 45px;
            height: 30px;
            border: none;
            color: var(--text-color-main);
            border-radius: 11px;
            background-color: transparent;
            cursor: pointer;
        }

        .option-button {
            transition: background-color 0.1s ease;
            margin-bottom: 6px;
            
            body.hasHover &:hover {
                background-color: var(--tile-background);
            }

            &.selected {
                background-color: var(--shoebox-yellow);
                color: var(--consistent-main);

                body.hasHover &:hover {
                    background-color: var(--shoebox-yellow);
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    } 
}

@media only screen and (max-width: 900px) {
    #account-value-tile {
        max-width: 550px;
    } 
}

@media only screen and (max-width: 500px) {
    #account-value-tile {

        .tile-header {
            padding: 15px;
            padding-bottom: 0;
        }

        canvas {
            position: absolute;
            margin-top: -40px;
        }

        .interval-selector-wrapper {
            padding: 12px;
        }

        .interval-selector {
            margin: 15px;
        }
    } 
}