.personal-info-container {
    max-width: 500px;

    .info-line {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        label {
            margin-bottom: 10px;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        a {
            font-weight: 500;
            color: var(--shoebox-yellow);
            padding-right: 5px;
            cursor: pointer;
        }

        .terms-agreement-line {
            display: flex;
            align-items: center;

            div, sl-spinner[variant="search"] {
                margin-left: 5px;
            }

            .no-ack {
                color: var(--shoebox-error);
                margin-left: 5px;
            }
        }
    }

    .ack-line {
        margin-bottom: 7px;
    }

    .eligibility-block {
        margin-bottom: 30px;

        .eligibility-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            a {
                display: none;
                font-weight: 500;
                color: var(--shoebox-yellow);
                padding-right: 5px;
                cursor: pointer;
            }
        }

        label {
            display: flex;
            align-items: center;

            svg {
                margin-left: 5px;
                cursor: pointer;
            }
        }

        .eligibility-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;

            a {
                font-weight: 500;
                color: var(--shoebox-yellow);
                padding-right: 5px;
                cursor: pointer;
                text-align: right;
            }
        }

        .status-line {
            display: flex;
            align-items: center;
            color: var(--text-color-secondary);
            font-style: italic;

            .eligible-chip {
                display: flex;
                align-items: center;
                
                .chip-text {
                    font-weight: 600;
                    font-style: normal;
                    color: var(--text-color-secondary);
                    margin-right: 5px;
                }

                svg {
                    font-size: 18pt;
                    color: var(--chart-neutral-color);
                    margin-right: 5px;
                }

                &.is-eligible {

                    .chip-text {
                        color: var(--text-color-secondary);
                    }

                    svg {
                        color: var(--shoebox-yellow);
                    }
                }
            }

            sl-badge.eligible-chip::part(base) {
                height: 40px;
            }
        }

        .dob-input-wrapper {
            display: grid;
            grid-template-rows: 0fr;
            overflow: hidden;
            margin-bottom: 15px;
            margin-top: 0px;
            opacity: 0;
            will-change: filter;
            filter: blur(10px);
            padding: 0px 2px;
            transition: grid-template-rows 0.3s ease, margin 0.3s ease, opacity 0.3s ease, filter 0.3s ease;

            &.expanded {
                grid-template-rows: 1fr;
                margin-bottom: 30px;
                margin-top: 16px;
                opacity: 1;
                will-change: filter;
                filter: blur(0px);
                padding: 2px;
            }
        }

        .dob-form {
            min-height: 0;
            display: grid;
            grid-template-columns: 1fr 235px;
            column-gap: 12px;
            width: 100%;
            
            .form-buttons {
                display: flex;
                column-gap: 8px;
            }
        }

        @media only screen and (max-width: 400px) {
            .status-line {
    
                .eligible-chip {
                    margin-right: 12px;
                    
                    .chip-text {
                        font-weight: 500;
                        font-size: 10pt;
                    }
    
                    svg {
                        font-size: 16pt;
                    }
                }

                sl-badge.eligible-chip::part(base) {
                    height: 30px;
                    padding: 5px;
                }
            }

            .dob-form {
                grid-template-columns: 1fr;
                row-gap: 12px;
                
                .form-buttons {
                    column-gap: 12px;
                    
                    sl-button {
                        width: 100%;
                    }
                }
            }
        }
    }

    // @media only screen and (max-width: 425px) {
    //     .eligibility-block {

    //         .eligibility-header {
    
    //             a {
    //                 display: inline-block;
    //             }
    //         }

    //         .eligibility-content {
    
    //             a {
    //                 display: none;
    //             }
    //         }
    //     }
    // }

    .switch-line {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        
        label {
            margin-right: 12px;
            white-space: nowrap;
        }

        div {
            width: 100%;
    
            sl-radio-group.profile-info-button-group::part(button-group) {
                width: 100%;
                max-width: 325px;
            }

            sl-radio-button.info-group-button {
                width: 100%;
            }

            sl-radio-button.info-group-button::part(button) {
                width: 100%;
                min-width: 75px;
            }

            .info-toggle-loader, .info-toggle-loader::part(base), .info-toggle-loader::part(indicator) {
                height: 50px;
                width: 100%;
                max-width: 325px;
                border-radius: 15px;
                --sheen-color: var(--background-color);
                --color: var(--tile-background);
            }
        }
    }

    .mobile-update {
        width: 100%;
    }
}

#catch-up-help-popup {

    h4 {
        display: flex;
        align-items: center;
        font-size: 14pt;
        font-weight: 600;
        margin-bottom: 6px;
        margin-top: 10px;

        svg {
            font-size: 18pt;
            margin-right: 5px;
            margin-bottom: 1px;
            color: var(--tile-info-icon);

            &.beach-icon {
                margin-right: 7px;
            }
        }
    }

    h4:first-of-type {
        margin-top: 0;
    }

    p {
        color: var(--text-color-secondary);
        padding-bottom: 10px;
        display: block;

        span {
         color: var(--text-color-secondary);
         font-weight: 600;
         font-style: oblique;
        }
    }

    p:last-of-type {
        margin-bottom: 0px;
    }
}

.profile-picture-edit-container {
    display: flex;
    align-items: center;

    .picture-display-container {

        img {
            height: 200px;
            width: 200px;
            border-radius: 100px;
        }

        .profile-picture-loader {
            height: 200px;
            width: 200px;
            border-radius: 100px;
            --sheen-color: var(--background-color);
            --color: var(--tile-background);
        }

        .photo-container {

            canvas {
                border-radius: 100px;
            }

            .zoom-controls {
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                margin-top: 12px;

                svg {
                    font-size: 18pt;
                    color: var(--consistent-main);
                    border-radius: 50px;
                    padding: 8px;
                    cursor: pointer;

                    &#profile-image-zoom-out {
                        background-color: var(--tile-hover);
                        color: var(--text-color-tertiary);
                    }

                    &#profile-image-zoom-in {
                        background-color: var(--shoebox-yellow);
                    }
                    
                }
            }
        }
    }

    .picture-edit-controls {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 0;
        margin-left: 25px;
        width: fit-content;
        transition: margin-top 0.3s ease;

        label {
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--sl-color-info-500);
            border-radius: 15px;
            width: 220px;
            font-weight: 500;
            font-family: 'Inter', sans-serif;
            text-align: center;
            height: 50px;
            transition: background-color 0.4s ease;
            cursor: pointer;

            body.hasHover &:hover {
                background-color: var(--sl-color-info-600);
            }

            span {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        sl-button {
            width: 220px;
        }

        input[type="file"] {
            display: none;
            height: 0;
        } 

        .remove-photo-button, .clear-selected-button {
            margin-top: 12px;
        }

        &.editing {
            margin-top: -52px;
        }

        #cancel-delete-action {
            width: 55px;
            display: flex;
            align-items: center;
            justify-content: center;
            animation: fade-in-cancel-control 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

                @keyframes fade-in-cancel-control {
                    0% {
                        -webkit-transform: translateY(10px);
                        transform: translateY(10px);
                        opacity: 0;
                        will-change: filter;
                        filter: blur(5px);
                    }
                    100% {
                        -webkit-transform: translateY(0);
                        transform: translateY(0);
                        opacity: 1;
                        will-change: filter;
                        filter: blur(0);
                    }
                }

            svg {
                font-size: 24pt;
            }
        }

        sl-button#cancel-delete-action::part(label) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.form-error-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    will-change: filter;
    filter: blur(10px);
    padding: 0;
    opacity: 0;
    transition: all 0.3s ease;

    &.shown {
        grid-template-rows: 1fr;
        will-change: filter;
        filter: blur(0px);
        padding-top: 10px;
        opacity: 1;
    }
}

.file-form-error {
    font-family: 'Reddit Mono', monospace;
    color: var(--shoebox-error);
    display: block;
}

@media only screen and (max-width: 500px) {
    .profile-picture-edit-container {
        flex-direction: column;

        .picture-edit-controls {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 25px;
            margin-left: 0px;
            transition: margin-top 0.3s ease;
            width: 100%;
    
            label {
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--sl-color-info-500);
                border-radius: 15px;
                font-weight: 500;
                width: 100%;
                max-width: 300px;
                font-family: 'Inter', sans-serif;
                text-align: center;
                height: 50px;
                transition: background-color 0.4s ease;
                cursor: pointer;
    
                body.hasHover &:hover {
                    background-color: var(--sl-color-info-600);
                }
    
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
    
            sl-button {
                width: 100%;
                max-width: 300px;
            }
    
            &.editing {
                margin-top: 25px;
            }
        }
    }

    .form-error-wrapper {
    
        &.shown {
            padding-top: 20px;
        }
    }

    .file-form-error {
        text-align: center;
    }
}

#terms-agreement-popup {
    h4 {
        font-size: 16pt;
        font-weight: 700;
        margin-bottom: 12px;
    }

    sl-button[variant="neutral"] {
        margin-right: 12px;
    }

    sl-button[variant="default"] {
        width: 114px;
    }
}

.terms-popup-body {
    .last-updated-date {
        background-color: var(--tile-background);
        padding: 5px 10px;
        width: fit-content;
        border-radius: 10px;
        color: var(--text-color-secondary);

        strong {
            font-weight: 600;
        }
    }

    h2 {
        font-weight: 300;
        margin-bottom: 12px;
        margin-top: 25px;
        font-size: 14pt;
    }

    a {
        color: var(--shoebox-yellow);
    }

    p {
        margin-bottom: 12px;

        strong {
            font-weight: 600;
        }
    }

    li {
        margin-bottom: 6px;
    }
}

@media only screen and (max-width: 740px) {
    .personal-info-container {
        max-width: none;
        margin: auto;
    
        .mobile-update {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 450px) {
    .personal-info-container {
        max-width: 500px;
    
        .switch-line {
            flex-direction: column;
            align-items: flex-start;

            label {
                margin-bottom: 10px;
            }

            div {
        
                sl-radio-group.profile-info-button-group::part(button-group) {
                    max-width: 450px;
                }
            }
        }
    }
}