#support-page {

    .support-page-header {
        position: fixed;
        padding-top: 10px;
        top: 70px;
        width: calc(100% - 50px);
        max-width: 1250px;
        background-color: var(--background-color);
        z-index: 98;
        display: flex;
        align-items: center;
        justify-content: space-between;
    
        h1 {
            margin-bottom: 15px;
        }
    }

    h2 {
        font-weight: 600;
        margin-bottom: 12px;
    }

    .support-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
        margin-top: 140px;

        svg {
            font-size: 48pt;
            margin-bottom: 12px;
        }
        div {
            font-size: 22pt;
        }
    }

    @media only screen and (max-width: 500px) {
        .support-header {
            margin-bottom: 25px;

            svg {
                font-size: 42pt;
                margin-bottom: 12px;
            }
            div {
                font-size: 18pt;
            }
        }
    }

    .support-search {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 100%;
        max-width: 700px;
        position: relative;
        margin-bottom: 30px;

        .search-container {
            position: relative;

            input {
                width: calc(100% - 30px);
                margin: auto;
            }

            svg, sl-spinner {
                position: absolute;
                right: 0;
                top: calc(50% - 1px);
                transform: translateY(-50%);
                font-size: 22pt;
                margin-right: 12px;
            }


        }

        
    }

    .active-support-options {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 100%;
        max-width: 700px;
        margin-bottom: 30px;

        h2 {
            display: flex;
            align-items: center;
        }

        .button-row {
            display: grid;
            grid-template-columns: 50% 50%;
            column-gap: 12px;
            width: calc(100% - 12px);

            svg {
                color: var(--shoebox-yellow);
            }

            sl-button {
                width: 100%;

                span {
                    font-weight: 600;
                }
            }

            a {
                width: 100%;
            }
        }
    }

    .quick-support {
        display: flex;
        flex-direction: column;
        margin: auto;
        width: 100%;
        max-width: 700px;
        margin-bottom: 10px;

        .support-docs-loader {
            sl-skeleton {
                height: 75px;
                border-radius: 15px;
                --sheen-color: var(--background-color);
                --color: var(--tile-background);
                margin-bottom: 12px;
            }

            sl-skeleton::part(indicator) {
                border-radius: 15px;
            }
        }

        .quick-support-items {

            .quick-support-card {
                background-color: var(--tile-background);
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px 16px;
                margin-bottom: 12px;
                border-radius: 15px;
                transition: background 0.3s ease;

                svg {
                    font-size: 24pt;
                    color: var(--text-color-tertiary);
                    transition: transform 0.3s ease;
                }

                .card-content {
                    display: flex;
                    align-items: center;

                    .category-icon {
                        margin-right: 10px;
                        font-size: 32pt;
                        color: var(--shoebox-yellow);
                    }

                    .title-and-info {
                        h2 {
                            margin: 0;
                            font-size: 16pt;
                        }

                        span {
                            color: var(--text-color-secondary);
                        }
                    }
                }

                &.selectable {
                    cursor: pointer;
                    body.hasHover &:hover {
                        background-color: var(--tile-hover);
                
                        svg:not(.category-icon) {
                            transform: translateX(5px);
                        }
                    }
                }

                
            }



            @media only screen and (max-width: 400px) {
                .quick-support-card {
                    padding: 10px 12px;
    
                    svg {
                        font-size: 18pt;
                    }
    
                    .card-content {
    
                        .category-icon {
                            margin-right: 7px;
                            font-size: 28pt;
                            min-width: 38px;
                        }
    
                        .title-and-info {
                            h2 {
                                font-size: 14pt;
                            }
    
                            span {
                                display: block;
                                font-size: 11pt;
                                margin-top: -3px;
                            }
                        }
                    }
                }
            }
        }
    }

    .coming-up-support-text {
        text-align: center;
        font-size: 12pt;
        color: var(--text-color-secondary);
        font-weight: 300;
        font-style: italic;
        margin-bottom: 30px;
    }
}