.expense-form-wrapper {
    height: calc(100% - 40px);
    overflow-y: scroll;
    padding: var(--body-spacing);
}

.expense-form {

    .name-block {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .date-amount-block {
        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: 12px;
        width: calc(100% - 12px);
        margin-bottom: 30px;

        span {
            display: flex;
            flex-direction: column;
        }

        .expense-amount-input {
            font-family: 'Reddit Mono', monospace;
        }

        input[type="date"] {
            // muy importante - safari seems to be the new ie ... sigh.
            -webkit-appearance: none;
        }
    }

    #expense-category-input {
        margin-bottom: 30px;
    }

    .hsa-eligible-block {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        sl-radio-button::part(button) {
            min-width: 60px;
        }

        span {
            margin-top: 33px;
            margin-left: 20px;

            p {
                color: var(--text-color-secondary);
            }

            a {
                display: flex;
                align-items: center;
                color: var(--shoebox-yellow);
                transition: color 0.3s ease;
                cursor: pointer;

                body.hasHover &:hover {
                    color: var(--shoebox-hover);
                }

                svg {
                    margin-left: 3px;
                }
            }
        }
    }

    @media only screen and (max-width: 400px) {
        .hsa-eligible-block {
            flex-direction: column;
            align-items: baseline;

            span {
                margin-left: 0;
                margin-top: 8px;

                &.not-sure-link {
                    p, a {
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    .insurance-claim-label {
        display: block;
        margin-bottom: 10px;
    }

    .insurance-claim-block {
        display: flex;
        align-items: flex-end;
        margin-bottom: 12px;

        label {
            white-space: pre-wrap;
        }

        sl-radio-button::part(button) {
            min-width: 60px;
        }

        sl-button {
            margin-left: 12px;
            width: 100%;
        }

        sl-input {
            width: 100%;
        }

        sl-input::part(base) {
            margin-bottom: 0px;
            margin-left: 12px;
            width: calc(100% - 12px);
        }
    }

    .selected-insurance-display {
        display: flex;
        align-items: center;

        small {
            margin-bottom: 0;
            margin-top: 5px;
            margin-right: 12px;
        }

        img {
            height: 25px;

            &.medium {
                height: 34px;
            }

            &.small {
                height: 38px;
            }
        }

        .manual-selection-display {
            font-size: 14pt;
            font-weight: 500;
            color: var(--luvly-lavendar);
        }
    }

    .insurance-option-tiles {
        height: 0px;
        opacity: 0;
        visibility: hidden;
        transition: height 0.3s ease, opacity 0.3s ease;
        margin-bottom: 18px;

        &.expanded {
            visibility: visible;
            opacity: 1;
            height: 458px;
            margin-bottom: 30px;
        }

        .insurance-tile {
            outline: 0px solid var(--shoebox-yellow);
            background-color: var(--tile-background);
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 15px;
            transition: outline 0.15s ease;

            &.selected {
                outline: 5px solid var(--shoebox-yellow);
            }

            img {
                height: 40px;

                &.medium {
                    height: 48px;
                }

                &.small {
                    height: 52px;
                }
            }
        }


        input {
            width: calc(100% - 30px);
        }
    }

    @media only screen and (max-width: 500px) {
        .insurance-option-tiles {

            &.expanded {
                height: 866px;
            }

            .button-row {
                margin-top: 10px;
                display: grid;
                grid-template-columns: 100%;
                row-gap: 12px;
                justify-content: space-between;
                width: calc(100%);
                margin-bottom: 12px;
            }
    
            .insurance-tile {
                outline: 0px solid var(--shoebox-yellow);
                background-color: var(--tile-background);
                height: 90px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 15px;
                transition: outline 0.15s ease;
    
                &.selected {
                    outline: 5px solid var(--shoebox-yellow);
                }
    
                img {
                    height: 40px;

                    &.medium {
                        height: 48px;
                    }

                    &.small {
                        height: 52px;
                    }
                }
            }
    
    
            input {
                width: calc(100% - 30px);
            }
        }
    }

    .withdrawn-expense-label {
        margin-bottom: 10px;
        display: block;
    }

    .withdrawn-expense-block {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        sl-radio-button::part(button) {
            min-width: 60px;
        }

        input {
            margin-bottom: 0px;
            margin-left: 12px;
            width: calc(100% - 12px);
        }
    }

    label {
        font-weight: 500;
        font-size: 14pt;
        margin-bottom: 10px;
        color: var(--text-color-main);
        display: flex;
        align-items: center;
    }

    .button-row {
        margin-top: 10px;
        display: grid;
        grid-template-columns: 50% 50%;
        column-gap: 12px;
        justify-content: space-between;
        width: calc(100% - 12px);
        margin-bottom: 12px;
    }

    .add-tags-btn {
        transition: all 0.3s ease;
    }

    .styled-input-blocks-button {
        height: 85px;

        label {
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 400;
            font-size: 12pt;
            background-color: var(--sl-color-info-500);
            border-radius: 15px;
            font-family: 'Inter', sans-serif;
            text-align: center;
            width: 100%;
            height: 100%;
            max-width: 300px;
            cursor: pointer;

            span {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                font-weight: 400;
                margin: 0;
                padding: 0;
                font-size: 13pt;

                svg {
                    font-size: 24pt;
                    margin-bottom: 4px;
                    color: var(--text-color-main);
                }

                .receipt-max {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    svg {
                        margin: 0 3px;
                        font-size: 16pt;
                    }
                }
            }

            transition: background-color 0.4s ease;
            cursor: pointer;

            body.hasHover &:hover {
                background-color: var(--tile-hover);
            }
        }

        input[type="file"] {
            display: none;
            height: 0;
        }

        &.disabled {
            cursor: not-allowed;

            label {
                pointer-events: none;
                
                span {
                    color: var(--text-color-disabled);

                    svg {
                        color: var(--text-color-disabled);
                    }
                }
            }
        }
    }

    .add-tags-state {
        margin-top: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        input {
            width: 100%;
            margin: 0 12px;
            min-width: 50px;
        }

        @media only screen and (max-width: 740px) {
            sl-button::part(label) {
                padding: 0 15px;
            }
        }

        .add-tag-plus-btn {
            width: 55px;
            display: flex;
            align-items: center;

            svg {
                font-size: 22pt;
            }
        }

        sl-button::part(label) {
            display: flex;
            align-items: center;
        }
    }

    .tag-list {
        sl-badge {
            margin-right: 10px;
            margin-bottom: 10px;
            animation: fade-in-tag 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-tag {
                0% {
                    transform: translateX(10px);
                    opacity: 0;
                }
                100% {
                    transform: translateX(0);
                    opacity: 1;
                }
            }

            svg {
                font-size: 14pt;
                margin-right: -4px;
                padding-left: 5px;
                cursor: pointer;
            }
        }
    }

    .receipt-list {
        display: flex;
        margin-bottom: 18px;
        height: fit-content;
        width: 100%;
        overflow-x: scroll;

        .expense-receipt {
            height: 184px;
            position: relative;
            margin-right: 12px;
            margin-bottom: 12px;
            animation: fade-in-receipt 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;

            @keyframes fade-in-receipt {
                0% {
                    transform: translateX(10px);
                    opacity: 0;
                }
                100% {
                    transform: translateX(0);
                    opacity: 1;
                }
            }

            svg.remove-img-icon {
                position: absolute;
                right: 0;
                font-size: 16pt;
                background-color: white;
                color: var(--consistent-black);
                border-radius: 50px;
                margin: 6px;
                cursor: pointer;
                z-index: 2;
            }

            svg.expand-img-icon {
                z-index: 2;
                font-size: 20pt;
                background-color: unset;
                color: var(--text-color-consistent-light);
                background-color: var(--consistent-black);
                opacity: 0;
                transform: scale(0.8);
                will-change: filter;
                filter: blur(10px);
                border-radius: 10px;
                padding: 10px;
                position: absolute;
                margin: auto;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                text-align: center;
                transition: all 0.3s ease;

                
            }

            img {
                border: 2px solid var(--tile-background);
                height: 100%;
                border-radius: 15px;
            }

            .pdf-thumbnail-wrapper {
                position: relative;
                z-index: 1;
                border-radius: 15px;

                .thumbnail-page {
                    border-radius: 15px;

                    .react-pdf__Thumbnail__page {
                        border-radius: 15px;
                    }
                }

                canvas {
                    border-radius: 15px;
                    border: 2px solid var(--tile-background);
                }
            }



            &.expanded {
                position: fixed;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 100vh;
                width: 100vw;
                top: 0;
                left: 0;
                background-color: var(--consistent-main-translucent);
                will-change: filter;
                backdrop-filter: blur(5px);
                z-index: 5000;

                img {
                    height: unset;
                    max-height: 95%;
                    max-width: 80%;
                    aspect-ratio: unset;
                }
            }

            &:hover {
                cursor: pointer;

                svg.expand-img-icon {
                    opacity: 0.65;
                    transform: scale(1);
                    will-change: filter;
                    filter: blur(0px);
                }
            }
        }

        .pdf-title {
            background-color: var(--consistent-black);
            color: white;
            padding: 5px 20px;
            border-radius: 50px;
            margin-top: 25px;
            font-family: 'Reddit Mono', monospace;
        }
    }

    .notes-block {
        display: flex;
        flex-direction: column;
    }

    .optional-indicator {
        background-color: var(--sl-color-info-600);
        padding: 2px 10px;
        margin-left: 12px;
        font-size: 12pt;
        border-radius: 7px;
        color: var(--text-color-main);
    }

    .form-error-wrapper {
        display: grid;
        grid-template-rows: 0fr;
        overflow: hidden;
        will-change: filter;
        filter: blur(10px);
        padding: 0;
        opacity: 0;
        transition: all 0.3s ease;

        &.shown {
            grid-template-rows: 1fr;
            will-change: filter;
            filter: blur(0px);
            padding-bottom: 10px;
            opacity: 1;
        }
    }

    .expense-form-error {
        font-family: 'Reddit Mono', monospace;
        color: var(--shoebox-error);
        display: block;
    }
}

.save-action {
    width: 100%;
}

.save-action::part(label) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-drawer-footer {
    display: grid;
    grid-template-columns: 50% 50%;
    width: calc(100% - 12px);
    column-gap: 12px;
    transition: grid-template-columns 0.4s ease;

    &.deleting {
        grid-template-columns: 15% 85%;

        sl-button.update-cancel-action {

            svg {
                font-size: 24pt;
            }
        }

        sl-button.update-cancel-action::part(label) {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .update-cancel-action::part(label) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .shoebox-drawer-action-button::part(label) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}