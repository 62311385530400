.account-details {
    .password-toggle {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            color: var(--text-color-secondary);
        }
    }

    .sign-up-form {
        width: 100%;
        max-width: 500px;
        
        sl-input {
            margin-bottom: 20px;
        }

        .input-block {
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;

            label {
                margin-bottom: 10px;
                font-weight: 500;
            }
        }

        .name-block {
            display: grid;
            grid-template-columns: 50% 50%;
            column-gap: 20px;
            width: calc(100% - 20px);
        }

        .toggles {
            label {
                display: block;
                font-weight: 500;
                font-size: 14pt;
                margin-bottom: 10px;
                color: var(--sl-color-black);
            }

            .label-with-help-icon {
                display: flex;
                align-items: center;
            }

            sl-radio-group {
                margin-bottom: 20px;
            }

            sl-radio-group.profile-info-button-group::part(button-group) {
                width: 100%;
                max-width: 500px;
            }

            sl-radio-button.info-group-button {
                width: 100%;
            }

            sl-radio-button.info-group-button::part(button) {
                width: 100%;
                min-width: 75px;
            }
        }

        .label-with-help-icon {
            display: flex;
            align-items: center;
            cursor: pointer;

            svg {
                margin-left: 5px;
            }
        }

        #sign-up-plan-type-popup, #sign-up-dob-popup {
            h4 {
                display: flex;
                align-items: center;
                font-size: 14pt;
                font-weight: 600;
                margin-bottom: 6px;
        
                svg {
                    font-size: 20pt;
                    margin-right: 7px;
                    margin-bottom: 2px;
                    color: var(--tile-info-icon);
                }
            }

            p:not(:last-of-type) {
                margin-bottom: 15px;
            }
        }
    }

    .alert {
        width: 100%;
        max-width: 500px;
        display: grid;
        grid-template-rows: 0fr;
        overflow: hidden;
        will-change: filter;
        filter: blur(10px);
        padding: 0;
        opacity: 0;
        transition: filter 0.3s ease, padding 0.3s ease;
        color: var(--shoebox-error);
        font-family: 'Reddit Mono', monospace;

        &.error {
            grid-template-rows: 1fr;
            will-change: filter;
            filter: blur(0px);
            padding-top: 10px;
            padding-bottom: 5px;
            opacity: 1;
        }
    }

    .terms-block {
        width: 100%;
        max-width: 500px;
        display: flex;
        align-items: center;
        padding: 15px 0 5px 0;

        span {
           color: var(--text-color-secondary);
           font-size: 12pt;  
           padding-left: 5px; 
           a {
            font-style: normal;
            color: var(--shoebox-yellow);
           }
        }
    }

    .sign-in-controls {
        width: 100%;
        max-width: 500px;

        sl-button {
            width: 100%;
            padding-top: 20px;
        }
    }
}

