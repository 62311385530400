.reset-password-page {

    h1 {
        margin-bottom: 5px;
    }

    .support-text {
        font-size: 18pt;
        color: var(--text-color-secondary);
    }

    .email-error-wrapper {
        display: grid;
        grid-template-rows: 0fr;
        overflow: hidden;
        will-change: filter;
        filter: blur(10px);
        margin-top: 25px;
        padding: 0;
        opacity: 0;
        transition: all 0.3s ease;

        &.shown {
            grid-template-rows: 1fr;
            will-change: filter;
            filter: blur(0px);
            padding-bottom: 10px;
            opacity: 1;
        }
    }

    .email-form-error {
        font-family: 'Reddit Mono', monospace;
        color: var(--shoebox-error);
        display: block;
    }

    .input-wrapper {
        display: flex;
        align-items: flex-end;

        .input-block {
            display: flex;
            flex-direction: column;
        }

        label {
            font-weight: 500;
            font-size: 14pt;
            margin-bottom: 10px;
            color: var(--sl-color-black);
        }

        input {
            width: 350px;
            margin-right: 16px;
        }
    }
}