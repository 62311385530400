.portfolio-page {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 25px;
    row-gap: 25px;
    margin-top: 160px;
    width: calc(100% - 25px);
    max-width: 1062px;

    .portfolio-tile {
        width: calc(100% - 50px);
        aspect-ratio: 16 / 10;
        background-color: var(--tile-background);
        border-radius: 25px;
        padding: 15px 25px;

        .tile-header {
            display: flex;
            flex-direction: column;

            small {
                margin-bottom: 0;
            }

            .account-value {
                font-size: 32pt;
                font-family: "Reddit Mono", monospace;
                font-weight: 500;
            }

            h2 {
                font-weight: 600;
                font-size: 22pt;
            }
        }

        &.loading {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media only screen and (max-width: 900px) {
    .portfolio-page {
        grid-template-columns: 1fr;
        column-gap: 0px;
        row-gap: 15px;
        width: calc(100%);

        .portfolio-tile {
            max-width: 500px;
            margin: auto;
        }
    } 
}

@media only screen and (max-width: 740px) {
    .portfolio-page {
        margin-top: 145px;
    }
}

@media only screen and (max-width: 500px) {
    .portfolio-page {
        grid-template-columns: 1fr;
        column-gap: 0px;
        row-gap: 15px;
        width: calc(100%);

        .portfolio-tile {
            width: calc(100% - 30px);
            padding: 15px 15px;

            .tile-header {

                .account-value {
                    font-size: 28pt;
                }

                h2 {
                    font-size: 18pt;
                }
            }
        }
    } 
}

@media only screen and (max-width: 450px) {
    .portfolio-page {

        .portfolio-tile {

            .tile-header {

                .account-value {
                    font-size: 24pt;
                }

                h2 {
                    font-size: 18pt;
                }
            }
        }
    } 
}

