:root {
    --background-color: #ffffff;
    --scrollbar-thumb: #dcdcdc;
    --scrollbar-track: #ffffff;
    --text-color-main: #000000;
    --text-color-disabled: #8c8c8c;
    --text-color-consistent-light: #FFFFFF;
    --stat-bg: #ffecd0;
    --stat-bg-one: #ffecd0c1;
    --stat-bg-two: #fff2dfc9;
    --tile-background: #F4F4F4;
    --tile-background-transparent: #F4F4F400;
    --tile-button-background: #dcdcdc;
    --tile-button-background-translucent: #dcdcdc5d;
    --skeleton-sheen-color: #ffffff;
    --skeleton-background-color: #F4F4F4;
    --table-border: #dcdcdc;
    --tile-chart-background: #ebebeb;
    --tile-chart-light-gradient: #FADE92;
    --tile-chart-light-error-gradient: #ff7676;
    --tile-chart-deep-error-gradient: #ff3d3d;
    --tile-background-fade: #F4F4F400;
    --tile-info-icon: #000000;
    --table-upcoming: #868686;
    --tile-hover: #efefef;
    --tabs-shadow: #00000010;
    --overlay-background: #e2e2e280;
    --chart-neutral-color: #D8D8D8;
    --menu-drop-shadow: #00000025;
    --menu-item-inactive: #636363;
    --consistent-main: #000000;
    --consistent-main-translucent: #00000020;
    --badge-neutral: #F4F4F4;
    --badge-primary: #ffe6a3;
    --badge-small-neutral-bg: #e4e4e4af;
    --badge-small-neutral-text: #9d9d9d;
    --badge-small-success-bg: #2dff7a68;
    --badge-small-success-text: #0a983e;
    --shoebox-yellow: #ffc832;
    --shoebox-yellow-transparent: #ffc83200;
    --shoebox-hover: #fdce4d;
    --text-color-secondary: #8D8D8D;
    --text-color-tertiary: #c2c2c2;
    --feature-headers: #7B7B7B;
    --shoebox-error: #FF5454;
    --shoebox-error-hover: #ff3d3d;
    --shoebox-error-disabled: #ff7676;

    // Buttons 
    --button-background-disabled: #ffdd7e;
    --button-spinner-indicator: #ffe281;
    --button-text-disabled: #bdbdbd;
    --button-primary-text-disabled: #c4a03d;
    --button-secondary: #CECECE;
    --button-secondary-hover: #c6c6c6;

    // Toast Palette
    --success-toast-background: #e9ffe2;
    --success-toast-icon: #22c55e;
    --primary-toast-background: #edf3ff;
    --primary-toast-icon: #1082FF;
    --danger-toast-background: #ffeaea;
    --danger-toast-icon: #FF5454;

    // Misk Hues
    --blocks-blue: #1082FF;
    --blocks-blue-light: #3596ff;
    --blocks-blue-hover: #0065d1;
    --luvly-lavendar: #7a7ae3;
    --savings-chip: #97E79F50;
}

[data-theme="dark"] {
    --background-color: #131313;
    --scrollbar-thumb: #353535;
    --scrollbar-track: #131313;
    --text-color-main: #EAEAEA;
    --text-color-disabled: #8c8c8c;
    --text-color-consistent-light: #EAEAEA;
    --tile-background: #262626;
    --tile-background-transparent: #26262600;
    --tile-button-background: #353535;
    --tile-button-background-translucent: #3535355d;
    --skeleton-sheen-color: #7B7B7B;
    --skeleton-background-color: #353535;
    --table-border: #353535;
    --tile-chart-background: #2b2b2b;
    --tile-chart-light-gradient: #FADE92;
    --tile-chart-light-error-gradient: #ff7676;
    --tile-chart-deep-error-gradient: #ff3d3d;
    --tile-info-icon: #a2a2a2;
    --table-upcoming: #a2a2a2;
    --tile-hover: #1f1f1f;
    --tile-hover-transparent: #1f1f1f00;
    --tabs-shadow: #181818ab;
    --overlay-background: #2d2d2d80;
    --drawer-shadow: #11111153;
    --menu-item-inactive: #D8D8D8;
    --consistent-main: #131313;
    --consistent-main-translucent: #13131320;
    --badge-neutral: #262626;
    --badge-primary: #eacb79;
    --badge-small-neutral-bg: #e4e4e443;
    --badge-small-neutral-text: #b6b6b6;
    --badge-small-success-bg: #2dff7a2a;
    --badge-small-success-text: #17be54;
    --shoebox-yellow: #ffc832;
    --shoebox-yellow-transparent: #ffc83200;
    --shoebox-hover: #F8B600;
    --text-color-secondary: #8D8D8D;
    --feature-headers: #7B7B7B;
    --shoebox-error: #FF5454;
    --shoebox-error-hover: #ff3d3d;
    --shoebox-error-disabled: #ff7676;

    // Buttons
    --button-background-disabled: #927422;
    --button-text-disabled: #505050;
    --button-primary-text-disabled: #262626;
    --button-secondary-hover: #5b5b5b;
    --button-secondary: #515151;

    // Toast Palette
    --success-toast-background: #303b2e;
    --success-toast-icon: #22c55e;
    --primary-toast-background: #282a2e;
    --primary-toast-icon: #1082FF;
    --danger-toast-background: #302121;
    --danger-toast-icon: #FF5454;
}