footer {
    width: 100%;
    padding: 35px 0 25px 0;
    // margin-bottom: -50px;
    position: absolute;
    bottom: 0px;
    left: 0;
}

.footer-container {
    height: 100%;
    max-width: 1250px;
    width: calc(100vw - 50px);
    padding: 0 25px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .footer-links {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        max-width: 450px;

        .link-section {
            display: flex;
            padding-right: 25px;
            flex-direction: column;
            max-width: 200px;

            h4 {
                font-weight: 500;
            }

            .line-with-badge {
                display: flex;
                align-items: center;
            }

            a {
                margin-top: 15px;
                text-decoration: none;
                color: var(--shoebox-yellow);
                display: flex;
                align-items: center;
                width: fit-content;
                height: 19.5px;
            }

            span {
                margin-top: 15px;
                background-color: lightgray;
                padding: 5px 10px;
                font-size: 10pt;
                border-radius: 10px;
                font-weight: 500;
                color: white;
                margin-left: 10px;
                cursor: default;

                &.coming-soon-badge {
                    background-color: #D9D9D9;
                    white-space: nowrap;
                }

                &.new-badge {
                    background-color: var(--blocks-blue);
                }
            }
        }
    }

    .footer-copyright {
        display: flex;
        align-items: center;
        margin-top: 35px;
        font-size: 12pt;

        img {
            height: 50px;
            width: 50px;
            margin-right: 12px;
        }
    }
}

@media only screen and (max-width: 750px) {
    .footer-container {
        padding: 0 15px;
        width: calc(100vw - 30px);
    }
}

@media only screen and (max-width: 600px) {

    .footer-container {
        padding: 0 15px;
        width: calc(100vw - 30px);

        .footer-links {
            grid-template-columns: 50% 40%;
            width: calc(100%);
    
            .link-section {
                display: flex;
                flex-wrap: wrap;
                padding-right: 0px;
                flex-direction: column;
                max-width: unset;
            }

            .link-section:last-child {
                padding-left: 15px;
            }

            // .link-section:last-child {
            //     margin-top: 35px;
            //     grid-column: 1 / 3;
            // }
        }

        .footer-copyright {
            flex-direction: column;
            text-align: center;
            width: calc(100%);
            margin-top: 25px;

            img {
                height: 45px;
                width: 45px;
                margin: 0;
            }
            
            div {
                display: flex;
                flex-direction: column;
            }
        }
    }
}