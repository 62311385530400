.portfolio-header {
    position: fixed;
    padding-top: 10px;
    top: 70px;
    width: calc(100% - 50px);
    max-width: 1250px;
    background-color: var(--background-color);
    z-index: 98;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
        margin-bottom: 15px;
    }

    svg {
        font-size: 28pt;
        padding: 0 3px;
        margin-bottom: 17px;
        color: var(--shoebox-yellow);
        cursor: pointer;
    }

    .configuration-btn {
        margin-bottom: 17px;

        svg {
            font-size: 18pt;
            padding: 0;
            margin: 0;
            margin-right: 5px;
            color: var(--consistent-main);
        }
    }

    .configuration-btn::part(label) {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.inactive-sub-tile {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    max-width: 500px;
    background-color: var(--tile-background);
    padding: 15px; 
    border-radius: 25px;
    margin: auto;
    margin-top: 170px;


    svg {
        font-size: 24pt;
        color: var(--shoebox-error);
    }

    span {
        margin-top: 10px;
        color: var(--text-color-secondary);

        &.link {
            text-decoration: underline;
            cursor: pointer;
            font-weight: 500;
            padding-bottom: 10px;
        }
    }
}

.unconfigured-portfolio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65vh;
    text-align: center;
    animation: fade-in-splash 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    
    .dashboard-icon {
        height: 43px;

        &.new-folio-header {
            height: 53px;
        }
    }

    @keyframes fade-in-splash {
        0% {
          -webkit-transform: translateY(20px);
                  transform: translateY(20px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
    }

    .dashboard-icon {
        font-size: 40pt;
        color: var(--shoebox-yellow);
        margin-bottom: 10px;
    }

    h2 {
        font-weight: 500;
    }

    span {
        color: var(--text-color-secondary);
        margin-top: 5px;
        margin-bottom: 25px;
    }
}

.loading-portfolio {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65vh
}